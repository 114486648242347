import { ULID } from './ULID';
import { DateTime } from 'luxon';

export const keyClean = (key: string | null | undefined): string => {
    if (!key || typeof key !== 'string') {
        return '';
    }
    return key.trim().toLowerCase().replaceAll(' ', '-');
};

export const keyCompare = (keyOne: string | null | undefined, keyTwo: string | null | undefined): boolean => {
    return keyClean(keyOne) === keyClean(keyTwo);
};

export const keyPretty = (key: string | null | undefined): string => {
    if (key == null) {
        return 'NA';
    }
    return key.replaceAll('-', ' ').replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
};

export function keyBuilder(keyArray: string[], kwargs: any): string {
    let sortKey: string[] = [];

    for (let key of keyArray) {
        let tKey = singleKeyBuilder(key, kwargs);
        if (tKey !== 'GROUPTREE' && tKey !== 'ROOT') {
            tKey = tKey.toLowerCase().replaceAll(' ', '-');
        }
        sortKey.push(tKey);
    }

    return sortKey.join('#');
}

export function singleKeyBuilder(key: string, kwargs: any): string {
    let tKey = key;

    if (tKey.startsWith('<') && tKey.endsWith('>')) {
        const varKey = tKey.slice(1, -1);
        if (varKey.split(':')[0] === 'ULID') {
            const ulidSplit = varKey.split(':');
            let dt;
            if (kwargs['ulid']) {
                tKey = String(kwargs['ulid']);
            } else if (ulidSplit.length > 1) {
                const dtVar = findVariable(ulidSplit[1], kwargs);
                dt = new Date(dtVar); // You'll need to provide this function's TypeScript version
                const ulid = ULID.fromDatetime(dt); // Assuming a TypeScript ULID library with similar function
                tKey = String(ulid);
            } else {
                const ulid = ULID.now();
                tKey = String(ulid);
            }
        } else {
            tKey = findVariable(varKey, kwargs);
        }
    }

    return tKey;
}

export function findVariable(varMatch: string, kwargs: any): string {
    if (kwargs[varMatch]) {
        return kwargs[varMatch];
    } else {
        throw new Error(`Variable in Dynamo key not found! variable: ${varMatch} was not found in available set`);
    }
}

export function buildCleanObject(
    formInputs: DataObservation | GroupTreeBranch,
    metadata: Metadata,
    objectClass: MetadataTypes
): { [key: string]: string | number | any } {
    const pk = keyBuilder(metadata['partition_key']!, formInputs);
    let sk;
    if (formInputs.sk) {
        sk = formInputs.sk;
    } else {
        sk = keyBuilder(metadata['sort_key']!, formInputs);
    }
    const lastUpdate = DateTime.now().toISO()!;
    const objectType = metadata.name.toLowerCase();

    // console.log('pk: ', pk);
    // console.log('sk: ', sk);
    // console.log('lastUpdate: ', lastUpdate);

    let cleanInputs: { [key: string]: string | number | any } = {};

    cleanInputs['pk'] = pk;
    cleanInputs['sk'] = sk;
    cleanInputs['objectClass'] = objectClass;
    cleanInputs['objectType'] = objectType;
    cleanInputs['lastUpdate'] = lastUpdate;

    metadata.attributes.forEach((attr) => {
        // console.log('attr: ', attr.name);
        // console.log('keys: ', Object.keys(formInputs));
        if (attr.name in formInputs) {
            // @ts-ignore
            cleanInputs[attr.name] = formInputs[attr.name];
        }
    });

    console.log('cleanInputs: ', cleanInputs);

    return cleanInputs;
}

// export function buildCleanInputs(
//     formInputs: DataObservation | GroupTreeBranch,
//     metadata: Metadata,
//     objectClass: MetadataTypes
// ): string[] {
//     const pk = keyBuilder(metadata['partition_key']!, formInputs);
//     let sk;
//     if (formInputs.sk) {
//         sk = formInputs.sk;
//     } else {
//         sk = keyBuilder(metadata['sort_key']!, formInputs);
//     }
//     const lastUpdate = DateTime.now().toISO()!;
//     const objectType = metadata.name.toLowerCase();

//     // console.log('pk: ', pk);
//     // console.log('sk: ', sk);
//     // console.log('lastUpdate: ', lastUpdate);

//     let cleanInputs: string[] = [];

//     cleanInputs.push(`pk: "${pk}"`);
//     cleanInputs.push(`sk: "${sk}"`);
//     cleanInputs.push(`objectClass: "${objectClass}"`);
//     cleanInputs.push(`objectType: "${objectType}"`);
//     cleanInputs.push(`lastUpdate: "${lastUpdate}"`);

//     metadata.attributes.forEach((attr) => {
//         // console.log('attr: ', attr.name);
//         // console.log('keys: ', Object.keys(formInputs));
//         if (attr.name in formInputs) {
//             // @ts-ignore
//             const val = formInputs[attr.name];
//             if (attr.datatype === 'number') {
//                 cleanInputs.push(`${attr.name}: ${val}`);
//             } else {
//                 cleanInputs.push(`${attr.name}: ${JSON.stringify(val)}`);
//             }
//         }
//     });

//     // for (const [key, value] of Object.entries(inputs)) {
//     //     console.log('checking type for: ', key);
//     //     console.log('val type: ', typeof value);
//     //     if (typeof value === 'number') {
//     //         cleanInputs.push(`${key}: ${value}`);
//     //     } else {
//     //         cleanInputs.push(`${key}: "${value}"`);
//     //     }
//     // }

//     console.log('cleanInputs: ', cleanInputs);

//     return cleanInputs;
// }
