import React, { PropsWithChildren, useState } from 'react';
import WSELogo from '../../assets/wse/WSE-Logo-Website-White-Text@0.5x.png';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SIGNIN_BACKGROUND_IMG } from '../../services/constants';
import './OnboardingStyles.css';
// Header blue #005387
//  Button Green #328b35

const OnboardingLayout = ({ children }: PropsWithChildren<any>) => {
    const [leaveOnboardingModal, setLeaveOnboardingModal] = useState(false);
    const navigate = useNavigate();

    console.log('children', children);

    return (
        <div>
            <header style={{ backgroundColor: '#005387', height: '95px' }}>
                <nav className="navbar navbar-expand-lg navbar-light py-1">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src={WSELogo} alt="WSE Logo" width="200" />
                        </a>

                        <div className=" " id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item pe-4">
                                    <Button
                                        style={{ backgroundColor: '#328b35', border: 'none' }}
                                        onClick={() => setLeaveOnboardingModal(true)}>
                                        Back To My Account
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <Modal show={leaveOnboardingModal} onHide={() => setLeaveOnboardingModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Are you sure you want to leave the onboarding process? Progress may not be saved.</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Button variant="outline-success" onClick={() => setLeaveOnboardingModal(false)}>
                            No, Let me finish
                        </Button>
                        <Button variant="success" onClick={() => navigate('/')}>
                            Yes, Leave
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <main>
                <div
                    className="full-page-background"
                    style={{
                        // backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("${SIGNIN_BACKGROUND_IMG}") no-repeat center center fixed`,
                        backgroundImage: `url("${SIGNIN_BACKGROUND_IMG}")`,
                        backdropFilter: 'blur(10px)',
                    }}>
                    {children}
                </div>
            </main>
            {/* <footer className="bg-light text-center text-lg-start">
                <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    © 2023 Western Sustainability Exchange
                </div>
            </footer> */}
        </div>
    );
};

export default OnboardingLayout;
