import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import notFoundImg from 'assets/images/startman.svg';
import Logo from 'assets/wse/WSE-Logo-Color-Horiz-blue.svg';

const SomethingWentWrong = () => {
    return (
        <>
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <div className="container">
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5} xxl={4}>
                            <Card>
                                {/* logo */}
                                <Card.Header className="pt-3 text-center bg-grey">
                                    <Link to="/">
                                        <span>
                                            <img src={Logo} alt="" height="64" />
                                        </span>
                                    </Link>
                                </Card.Header>

                                <Card.Body className="p-4">
                                    <div className="text-center">
                                        <img src={notFoundImg} height="120" alt="" />

                                        <h1 className="text-error mt-4">Whoops!</h1>
                                        <h4 className="text-uppercase text-danger mt-3">Something went wrong!</h4>
                                        <p className="text-muted mt-3">
                                            We have logged this issue and will work on fixing it as soon as possible.
                                        </p>
                                        <br />
                                        <p className="text-muted mt-3">Until then, click below to try again</p>

                                        <Link className="btn btn-info mt-3" to="/">
                                            <i className="mdi mdi-reply"></i> Return Home
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default SomethingWentWrong;
