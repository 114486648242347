export const GroupTree: Metadata[] = [
    {
        name: 'ranch',
        pk: 'METADATA',
        attributes: [
            {
                name: 'name',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'originalShapefile',
                datatype: 'string',
                unit: 'NA',
                required: false,
            },
            {
                name: 'program',
                value: 'Rancher Network',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'centerpoint',
                datatype: 'array',
                unit: 'NA',
                required: true,
            },
            {
                name: 'boundingBox',
                datatype: 'array',
                unit: 'NA',
                required: true,
            },
        ],
        data_observations: ['Herd Info'],
        group_path: ['<ranch>'],
        partition_key: ['GROUPTREE', '<name>'],
        sort_key: ['ROOT'],
        display_on_map: false,
        label: 'Ranches',
    },
    {
        name: 'herd',
        depends_on: 'Ranch',
        attributes: [
            {
                name: 'name',
                datatype: 'text',
                unit: 'NA',
                required: true,
                label_text: 'Herd Name',
            },
            {
                name: 'herdType',
                datatype: 'str',
                unit: 'NA',
                required: true,
                label_text: 'Herd Type',
                widget_type: 'select',
            },
            {
                name: 'herdSize',
                datatype: 'number',
                unit: 'NA',
                required: true,
                label_text: 'Herd Size (sets default, can be changed)',
                widget_type: 'number',
            },
            {
                name: 'rancherWeight',
                datatype: 'number',
                unit: 'NA',
                required: true,
                label_text: 'Rancher Weight',
                widget_type: 'number',
            },

            // {
            //     name: 'currentPasture',
            //     datatype: 'text',
            //     unit: 'NA',
            //     required: false,
            //     is_hidden_in_table: true,
            // },
            // {
            //     name: 'geometry',
            //     datatype: 'Point',
            //     unit: 'NA',
            //     required: false,
            //     is_hidden_in_table: true,
            // },
        ],
        source: 'airtable',
        data_observations: [],
        group_path: ['<ranch>', '<name>'],
        partition_key: ['GROUPTREE', '<ranch>'],
        sort_key: ['herd', '<name>'],
        display_on_map: true,
        icon: 'livestock.png',
        tooltip: true,
        label: 'Herds',
    },
    {
        name: 'managment-unit',
        depends_on: 'Ranch',
        source: 'airtable',
        attributes: [
            {
                name: 'ranch',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'name',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'centerpoint',
                datatype: 'array',
                unit: 'NA',
                required: true,
            },
            {
                name: 'boundingBox',
                datatype: 'array',
                unit: 'NA',
                required: true,
            },
        ],
        data_observations: [],
        group_path: ['<ranch>', '<name>'],
        partition_key: ['GROUPTREE', '<ranch>'],
        sort_key: ['<name>'],
        display_on_map: false,
        label: 'Management Units',
    },
    {
        name: 'pasture',
        depends_on: 'Management Unit',
        source: 'airtable',
        attributes: [
            {
                name: 'ranch',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'managementUnit',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'name',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'geometry',
                datatype: 'polygon',
                unit: 'NA',
                required: true,
            },
            {
                name: 'geohash',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'centerpoint',
                datatype: 'array',
                unit: 'NA',
                required: true,
            },
        ],
        data_observations: ['Pasture Info', 'Grazing Data', 'SDA Monthly', 'Graze Seasons', 'Grazing Plan'],
        partition_key: ['GROUPTREE', '<ranch>'],
        group_path: ['<ranch>', '<managementUnit>', '<name>'],
        sort_key: ['<managementUnit>', '<name>'],
        display_on_map: true,
        tooltip: false,
        label: 'Pastures',
    },
    {
        name: 'sample-location',
        depends_on: 'Ranch',
        attributes: [
            {
                name: 'name',
                datatype: 'string',
                unit: 'NA',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'geometry',
                datatype: 'Point',
                unit: 'NA',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'sampleSite',
                datatype: 'str',
                unit: 'NA',
                required: false,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'sampleType',
                datatype: 'str',
                unit: 'NA',
                required: true,
                widget_type: 'Single Select Dropdown',
                key_value_pairs: [],
                range: 'NA',
            },
        ],
        data_observations: ['Soil Samples'],
        partition_key: ['GROUPTREE', '<ranch>'],
        sort_key: ['<name>'],
        group_path: ['<ranch>', '<name>'],
        reference_data: true,
        display_on_map: true,
        icon: 'soil.png',
        tooltip: true,
        label: 'Sample Locations',
    },
    {
        name: 'pasture-split',
        depends_on: 'pasture',
        attributes: [
            {
                name: 'ranch',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'managementUnit',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'pasture',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'name',
                datatype: 'string',
                unit: 'NA',
                required: true,
            },
            {
                name: 'geometry',
                datatype: 'polygon',
                unit: 'NA',
                required: false,
            },
            {
                name: 'acres',
                datatype: 'number',
                unit: 'Acres',
                required: true,
            },
            {
                name: 'active',
                datatype: 'boolean',
                required: true,
                is_hidden_in_table: true,
            },
        ],
        partition_key: ['GROUPTREE', '<ranch>'],
        sort_key: ['<managementUnit>', '<pasture>', '<ULID>'],
        group_path: ['<ranch>', '<managementUnit>', '<pasture>', '<name>'],
        label: 'Pasture Splits',
    },
];

export const DataObservations: Metadata[] = [
    {
        name: 'pasture-info',
        depends_on: 'Pasture Info',
        attributes: [
            {
                name: 'pastureName',
                datatype: 'string',
                unit: 'NA',
                required: true,
                label_text: 'Pasture Name',
                hint_text: 'Pasture',
                widget_type: 'Single Select Dropdown',
                range: 'NA',
            },
            {
                name: 'alternateNames',
                datatype: 'string',
                unit: 'NA',
                label_text: 'Alternate Names',
                hint_text: 'Alternate Pasture Names',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'sizeAcres',
                datatype: 'float',
                unit: 'acre',
                required: true,
                label_text: 'Size (Acres)',
                hint_text: 'Size in Acres',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'carryingCapacity',
                datatype: 'str',
                unit: 'NA',
                required: false,
                label_text: 'Carrying Capacity',
                hint_text: 'Carrying Capacity',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'lastSeedSet',
                datatype: 'date',
                unit: 'NA',
                required: false,
                label_text: 'Last Seed Set',
                hint_text: 'Date that Last seed was set',
                widget_type: 'DateTime Picker',
                range: 'NA',
            },
            {
                name: 'priorYearSDA',
                datatype: 'float',
                unit: 'SDA',
                required: false,
                label_text: 'Prior Year SDA',
                is_editable: false,
            },
            {
                name: 'recoveryDays',
                datatype: 'float',
                unit: 'days',
                required: false,
                label_text: 'Recovery Days',
                is_editable: false,
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'str',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'managementUnit',
                datatype: 'string',
                unit: 'NA',
                required: true,
                label_text: 'Management Unit',
                hint_text: 'Date',
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        partition_key: ['<ranch>'],
        sort_key: ['pasture-info', '<managementUnit>', '<pastureName>'],
        group_path: ['<ranch>', '<managementUnit>', '<pastureName>'],
        reference_data: true,
    },
    {
        name: 'herd-info',
        attributes: [
            {
                name: 'herdName',
                datatype: 'str',
                unit: 'NA',
                required: true,
                label_text: 'Herd Name',
                widget_type: 'input',
                is_editable: true,
                range: 'NA',
            },
            {
                name: 'herdType',
                datatype: 'str',
                unit: 'NA',
                required: true,
                label_text: 'Herd Type',
                widget_type: 'Single Select Dropdown',
                key_value_pairs: [
                    {
                        key: 'Cow',
                        keyvalue: 'Cow',
                    },
                    {
                        key: 'Heifer',
                        keyvalue: 'Heifer',
                    },
                    {
                        key: 'Pairs',
                        keyvalue: 'Pairs',
                    },
                    {
                        key: 'Weaned',
                        keyvalue: 'Weaned',
                    },
                    {
                        key: 'Yearling',
                        keyvalue: 'Yearling',
                    },
                    {
                        key: 'Bull',
                        keyvalue: 'Bull',
                    },
                    {
                        key: 'Mixed',
                        keyvalue: 'Mixed',
                    },
                    {
                        key: 'Horse',
                        keyvalue: 'Horse',
                    },
                ],
                range: 'NA',
            },
            {
                name: 'wseWeight',
                datatype: 'int',
                unit: 'NA',
                required: false,
                label_text: 'WSE Weight',
                widget_type: 'input',
                range: 'NA',
                is_editable: false,
            },
            {
                name: 'rancherWeight',
                datatype: 'int',
                unit: 'NA',
                required: false,
                label_text: 'Weight',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'animalConversion',
                datatype: 'float',
                unit: 'NA',
                required: false,
                label_text: 'Animal Conversion',
                widget_type: 'input',
                range: 'NA',
                is_editable: false,
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'str',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        partition_key: ['<ranch>'],
        sort_key: ['herd-info', '<herdName>'],
        group_path: ['<ranch>', '<herdName>'],
        delete_enabled: true,
        reference_data: true,
    },
    {
        name: 'grazing-data',
        attributes: [
            {
                name: 'pastureName',
                required: true,
                datatype: 'text',
                unit: 'NA',
                label_text: 'Pasture',
                widget_type: 'select',
                key_value_pairs: [],
                range: 'NA',
                is_hidden_in_table: false,
                is_editable: true,
            },
            {
                name: 'dateIn',
                datatype: 'date',
                unit: 'NA',
                required: true,
                label_text: 'Date In',
                hint_text: 'Date livestock was moved into pasture',
                widget_type: 'Date Picker',
                is_editable: true,
                range: 'NA',
            },
            {
                name: 'dateOut',
                datatype: 'date',
                unit: 'NA',
                required: false,
                label_text: 'Date Out',
                hint_text: 'Date livestock was moved our of pasture',
                widget_type: 'Date Picker',
                range: 'NA',
            },
            {
                name: 'herdName',
                datatype: 'text',
                unit: 'NA',
                required: true,
                label_text: 'Herd Name',
                widget_type: 'select',
                key_value_pairs: [
                    {
                        key: 'graphql',
                        keyvalue:
                            "{ getDataObservations(dataset: 'herd-info', pk: @ranchID, nodePath: '') { items {herdName}}}",
                    },
                ],
                range: 'NA',
            },
            {
                name: 'herdSize',
                datatype: 'number',
                unit: 'NA',
                required: true,
                label_text: 'Herd Size',
                hint_text: '# of livestock',
                widget_type: 'input',
                range: 'NA',
            },

            // {
            //     name: 'cells',
            //     datatype: 'number',
            //     unit: 'NA',
            //     required: false,
            //     label_text: 'Cells',
            //     widget_type: 'input',
            //     is_hidden_in_table: true,
            //     range: 'NA',
            // },
            {
                name: 'cells',
                datatype: 'number',
                unit: 'NA',
                required: false,
                label_text: 'Split Name',
                widget_type: 'select',
                range: 'NA',
            },
            {
                name: 'cellSize',
                datatype: 'number',
                unit: 'NA',
                required: false,
                label_text: 'Cell Size',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'notes',
                datatype: 'text',
                unit: 'NA',
                required: false,
                label_text: 'Notes',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'text',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'managementUnit',
                datatype: 'text',
                unit: 'NA',
                required: true,
                label_text: 'Management Unit',
                hint_text: 'Date',
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        foreign_keys: ['pastureName:Pasture Info', 'herdName:Herd Info'],
        partition_key: ['<ranch>'],
        sort_key: ['grazing-data', '<managementUnit>', '<pastureName>', '<ULID:dateIn>'],
        group_path: ['<ranch>', '<managementUnit>', '<pastureName>'],
        'lsi-1': ['grazing-data', '<herdName>', '<ULID:dateIn>'],
        delete_enabled: true,
        label: 'Grazing History',
    },
    {
        name: 'herd-moves',
        index_name: 'lsi-1',
        extends_object: 'Grazing Data',
        attributes: [
            {
                name: 'herdName',
                datatype: 'str',
                unit: 'NA',
                required: true,
                label_text: 'Herd Name',
                widget_type: 'Single Location Dropdown',
                key_value_pairs: [],
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'pastureName',
                required: true,
                datatype: 'str',
                unit: 'NA',
                widget_type: 'Single Location Dropdown',
                key_value_pairs: [
                    {
                        key: 'graphql',
                        keyvalue:
                            "{ getDataObservations(dataset: 'pasture-info', pk: @ranchID, nodePath: '') { items {pastureName}}}",
                    },
                ],
                range: 'NA',
            },
        ],
    },
    {
        name: 'sda-monthly',
        depends_on: 'Grazing Data',
        attributes: [
            {
                name: 'pastureName',
                required: true,
                datatype: 'str',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'SDAMonth',
                required: true,
                label_text: 'SDA Month',
                datatype: 'month',
            },
            {
                name: 'grazeDays',
                required: true,
                label_text: 'Days Grazed',
                datatype: 'int',
            },
            {
                name: 'stockdaysPerAcre',
                required: true,
                label_text: 'Stockdays Per Acre',
                datatype: 'float',
            },
            {
                name: 'animalDensity',
                required: false,
                label_text: 'Animal Density',
                datatype: 'float',
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'str',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'managementUnit',
                datatype: 'string',
                unit: 'NA',
                required: true,
                label_text: 'Management Unit',
                hint_text: 'Date',
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        primary_key: ['pastureName', 'SDA Month'],
        foreign_keys: ['pastureName:Pasture Info'],
        sort_key: ['sda-monthly', '<managementUnit>', '<pastureName>', '<SDAMonth>'],
        partition_key: ['<ranch>'],
        group_path: ['<ranch>', '<managementUnit>', '<pastureName>'],
    },
    {
        name: 'graze-seasons',
        depends_on: 'SDA Monthly',
        attributes: [
            {
                name: 'pastureName',
                required: true,
                datatype: 'str',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'year',
                required: true,
                label_text: 'Year',
                datatype: 'year',
            },
            {
                name: 'grazeSeason',
                required: true,
                label_text: 'Season Grazed',
                datatype: 'int',
            },
            {
                name: 'yearGrazeSeason',
                required: false,
                datatype: 'str',
                is_hidden_in_table: true,
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'str',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'managementUnit',
                datatype: 'string',
                unit: 'NA',
                required: true,
                label_text: 'Management Unit',
                hint_text: 'Date',
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        primary_key: ['pastureName', 'year'],
        foreign_keys: ['pastureName:Pasture Info'],
        sort_key: ['graze-seasons', '<managementUnit>', '<pastureName>', '<year>'],
        partition_key: ['<ranch>'],
        group_path: ['<ranch>', '<managementUnit>', '<pastureName>'],
    },
    {
        name: 'annual-prcp',
        attributes: [
            {
                name: 'Year',
                datatype: 'date',
                unit: 'year',
                required: true,
                widget_type: 'NA',
                range: 'NA',
            },
            {
                name: 'Precipitation',
                datatype: 'float',
                unit: 'inch',
                convert_units_from: '.1mm',
                required: true,
                grouping: 'year',
                function: 'sum:PRCP',
                widget_type: 'NA',
                range: 'NA',
            },
        ],
        sort_key: ['annual-prcp', '<ULID>'],
        partition_key: ['<ranch>'],
        group_path: ['<ranch>', '<managementUnit>'],
    },
    {
        name: 'rolling-annual-prcp',
        attributes: [
            {
                name: 'Date',
                datatype: 'date',
                unit: 'day',
                required: true,
                widget_type: 'NA',
                range: 'NA',
            },
            {
                name: 'Precipitation',
                datatype: 'float',
                unit: 'inch',
                convert_units_from: '.1mm',
                required: true,
                rolling: 'year',
                function: 'sum:PRCP',
                widget_type: 'NA',
                range: 'NA',
            },
        ],
        sort_key: ['rolling-annual-prcp', '<ULID>'],
        partition_key: ['<ranch>'],
        group_path: ['<ranch>', '<managementUnit>'],
    },
    {
        name: 'soil-samples',
        attributes: [
            {
                name: 'sampleDate',
                datatype: 'date',
                unit: 'NA',
                required: true,
                widget_type: 'DateTime Picker',
                range: 'NA',
            },
            {
                name: 'bulkDensity',
                datatype: 'float',
                unit: 'NA',
                required: false,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'soilCarbon',
                datatype: 'float',
                unit: 'Tons/Acre',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'bareGround',
                datatype: 'int',
                unit: 'percent',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'soilSand',
                datatype: 'int',
                unit: 'percent',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'infiltration1',
                datatype: 'int',
                unit: 'minute',
                required: false,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'infiltration2',
                datatype: 'int',
                unit: 'minute',
                required: false,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'infiltration3',
                datatype: 'int',
                unit: 'minute',
                required: false,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'sampleTeam',
                datatype: 'str',
                unit: 'NA',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'litterCover',
                datatype: 'int',
                unit: 'percent',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'livePlantCover',
                datatype: 'int',
                unit: 'percent',
                required: true,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'notes',
                datatype: 'str',
                unit: 'NA',
                required: false,
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'sampleLocation',
                datatype: 'str',
                unit: 'NA',
                required: true,
                widget_type: 'input',
                is_editable: false,
                range: 'NA',
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'str',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        primary_key: ['sampleDate', 'sampleLocation'],
        partition_key: ['<ranch>'],
        sort_key: ['soil-samples', '<sampleLocation>', '<ULID:sampleDate>'],
        group_path: ['<ranch>', '<sampleLocation>'],
    },
    {
        name: 'grazing-plan',
        attributes: [
            {
                name: 'dateIn',
                datatype: 'date',
                unit: 'NA',
                required: true,
                label_text: 'Date In',
                hint_text: 'Date livestock will be moved into pasture',
                widget_type: 'Date Picker',
                is_editable: true,
                range: 'NA',
            },
            {
                name: 'dateOut',
                datatype: 'date',
                unit: 'NA',
                required: false,
                label_text: 'Date Out',
                hint_text: 'Date livestock will be moved out of pasture',
                widget_type: 'Date Picker',
                range: 'NA',
            },
            {
                name: 'herdName',
                datatype: 'text',
                unit: 'NA',
                required: true,
                label_text: 'Herd Name',
                widget_type: 'select',
                key_value_pairs: [
                    {
                        key: 'graphql',
                        keyvalue:
                            "{ getDataObservations(dataset: 'herd-info', pk: @ranchID, nodePath: '') { items {herdName}}}",
                    },
                ],
                range: 'NA',
            },
            {
                name: 'herdSize',
                datatype: 'number',
                unit: 'NA',
                required: true,
                label_text: 'Herd Size',
                hint_text: '# of livestock',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'pastureName',
                required: true,
                datatype: 'string',
                unit: 'NA',
                widget_type: 'Single Location Dropdown',
                key_value_pairs: [],
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            // {
            //     name: 'cells',
            //     datatype: 'number',
            //     unit: 'NA',
            //     required: false,
            //     label_text: 'Cells',
            //     widget_type: 'input',
            //     range: 'NA',
            // },
            {
                name: 'cellSize',
                datatype: 'text',
                unit: 'NA',
                required: false,
                label_text: 'Cell Size',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'notes',
                datatype: 'text',
                unit: 'NA',
                required: false,
                label_text: 'Notes',
                widget_type: 'input',
                range: 'NA',
            },
            {
                name: 'ranch',
                required: false,
                datatype: 'text',
                unit: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
            {
                name: 'managementUnit',
                datatype: 'text',
                unit: 'NA',
                required: true,
                label_text: 'Management Unit',
                hint_text: 'Date',
                range: 'NA',
                is_hidden_in_table: true,
                is_editable: false,
            },
        ],
        foreign_keys: ['pastureName:Pasture Info', 'herdName:Herd Info'],
        partition_key: ['<ranch>'],
        sort_key: ['grazing-plan', '<managementUnit>', '<pastureName>', '<ULID:dateIn>'],
        group_path: ['<ranch>', '<managementUnit>', '<pastureName>'],
        delete_enabled: true,
        'lsi-1': ['grazing-plan', '<herdName>', '<ULID:dateIn>'],
    },
];
