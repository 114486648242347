import { ulid as generateUlid, encodeTime } from 'ulid';

export class ULID {
    static fromDatetime(dt: Date): string {
        // Convert the JavaScript date to a timestamp in milliseconds.
        // Then divide by 1000 to get seconds since UNIX epoch.
        const timestamp = Math.floor(dt.getTime() / 1000);

        // Use the timestamp to generate a ULID for that specific time.
        return generateUlid(timestamp);
    }

    static now(): string {
        return generateUlid();
    }
}
