// import {httpClient} from './httpClient';
// import { appSyncClient } from './appSyncClient';
import { buildCleanObject, keyBuilder, keyClean } from '../helpers/dynamoKeyFunctions';
import { DateTime } from 'luxon';
import { GroupTree } from './MetadataData';
import axiosInstance from './apiCore';
import shp from 'shpjs';

export const createRanch = async (
    ranchName: string,
    geoJsonData: shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[],
    pastureKey: string
) => {
    const data = {
        ranchName,
        geoJsonData,
        pastureKey,
    };
    try {
        const res = await axiosInstance.post('createRanch', data);
        return res.data;
    } catch (e) {
        console.error('Issues creating Ranch ', e);
        throw e;
    }
};

export const createPastures = async (ranchName: string, pastures: CreatePasture[]) => {
    const data = {
        ranchName,
        pastures,
    };
    try {
        const res = await axiosInstance.post('createPastures', data);
        return res.data;
    } catch (e) {
        console.error('Issues creating pastures ', e);
        throw e;
    }
};

export const handleRanchEdits = async (ranchName: string, pastures: CreatePasture[]) => {
    const data = {
        ranchName,
        pastures,
    };
    try {
        console.log('upsert Pastures: ', pastures);
        const res = await axiosInstance.post('handleRanchEdits', data);
        return res.data;
    } catch (e) {
        console.error('Issues upserting pastures ', e);
        throw e;
    }
};

export const getGroupTreeByRanchID = async (ranchID: string) => {
    // console.log('try to get ranch ', ranchID);
    let ranchKey = keyClean(ranchID);
    // console.log('ranchKey: ', ranchKey);
    try {
        const pk = `GROUPTREE#${ranchKey}`;
        const res = await axiosInstance.post('getGroupTree', {
            pk,
        });
        // console.log('GroupTreeData: ', res);
        return res.data;
    } catch (error) {
        console.error('Issues getting groupTree ', error);
    }
};

export const getAllRanchesList = async () => {
    try {
        const res = await axiosInstance.get('getAllRanchesList');
        return res.data;
    } catch (error) {
        console.error('Issues getting all ranches ', error);
    }
};

export const getHerds = async (ranchID: string) => {
    let ranchKey = keyClean(ranchID);
    try {
        const pk = `GROUPTREE#${ranchKey}`;
        const sk = `herd#`;
        const res = await axiosInstance.post('getGroupTree', {
            pk,
            sk,
        });
        return res.data;
    } catch (error) {
        console.error('Issues getting Herds ', error);
    }
};

export const upsertGroupTree = async (formData: any, metadata: Metadata) => {
    // console.log('upsertGroupTree! FormData: ', formData);
    // console.log('metadata: ', metadata);

    const cleanObject = buildCleanObject(formData, metadata, 'GroupTree');
    // console.log('clean Object: ', cleanObject);

    const body = cleanObject;

    // console.log('body: ', body);
    try {
        const res = await axiosInstance.post('putGroupTree', body);
        // console.log('res: ', res);
        return res.data;
    } catch (e) {
        console.error(e);
    }
};

export const deleteGroupTreeLeaf = async (groupTreeObject: GroupTreeBranch, metadata: Metadata) => {
    const pk = keyBuilder(metadata['partition_key']!, groupTreeObject);
    let sk = keyBuilder(metadata['sort_key']!, groupTreeObject);

    if (groupTreeObject.sk) {
        sk = groupTreeObject.sk;
    }

    const body = {
        pk,
        sk,
    };

    // console.log('delete body: ', body);
    try {
        const data = await axiosInstance.post('deleteGroupTree', body);
        console.log('res: ', data);
        // if (data.length < 1) {
        //     throw new Error('Delete Fail, no records found for keys');
        // }
        return data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const updateRanchStatus = async (ranchName: string, status: string) => {
    const body = {
        ranchName,
        status,
    };
    try {
        const res = await axiosInstance.post('updateRanchStatus', body);
        return res.data;
    } catch (e) {
        console.error(e);
    }
};
