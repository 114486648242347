import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';

type SetQueryFunction = (value: string | boolean | number) => void;

export default function useQueryState(key: string): [string | undefined, SetQueryFunction] {
    const location = useLocation();
    const navigate = useNavigate();

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    // const locationString = location.search;
    // console.log('LocationSearch: ', locationString);
    // console.log('in useQueryState got query: ', query);
    // console.log('key: ', key);
    // console.log('query key: ', query[key]);

    const setQuery = useCallback(
        (value: string | boolean | number) => {
            const strValue = String(value);

            const existingQueries = qs.parse(location.search.replaceAll('?', ''), {
                ignoreQueryPrefix: true,
            });

            const queryString = qs.stringify({ ...existingQueries, [key]: strValue }, { skipNulls: true });

            navigate(`${location.pathname}?${queryString}`);
        },
        [navigate, location, key]
    );

    return [query[key] as string | undefined, setQuery];
}
