import appSyncClient from './appSyncClient';
import { DataObservations, GroupTree } from './MetadataData';

export const getGroupTreeMetadata = (dataset?: string): Metadata[] | Metadata | undefined => {
    if (!dataset) {
        return GroupTree;
    } else {
        return GroupTree.find((ds) => ds.name === dataset);
    }
};

export const getDataObservationsMetadata = (dataset?: string): Metadata[] | Metadata | undefined => {
    if (!dataset) return DataObservations;
    return DataObservations.find((ds) => ds.name === dataset);
};
