import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';
import { useAuth } from '../context/AuthContext';
// import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import SomethingWentWrong from 'pages/error/SomethingWentWrong';
import CellDetails from 'components/mapbox/components/offcanvastabs/CellDetails';
import { DateTime } from 'luxon';
import AuthLayout from 'layouts/WSEAuth/AuthLayout';
import OnboardingLayout from 'layouts/Onboarding/OnboardingLayout';
import { useData } from 'context/DataContext';
import NoRanches from 'pages/dashboard/alternatePages/NoRanches';
import RanchDisabled from 'pages/dashboard/alternatePages/RanchDisabled';
import OnboardingNotification from 'pages/dashboard/alternatePages/OnboardingNotification';
import { combine } from 'shpjs';
import PaymentRequired from 'pages/dashboard/alternatePages/PaymentRequired';

// lazy load all the views

// auth
const ForgotPassword = React.lazy(() => import('pages/account/newAuth/ForgotPassword/ForgotPassword'));
const SignInCustom = React.lazy(() => import('pages/account/newAuth/SignInCustom/SignInCustom'));
const SignUp = React.lazy(() => import('pages/account/newAuth/SignUp/SignUp'));
// const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));

// Payment
// const ProductSelect = React.lazy(() => import('pages/account/newAuth/Payments/ProductSelect'));
const paymentSuccess = React.lazy(() => import('pages/account/newAuth/Payments/PaymentSuccess'));
const paymentFail = React.lazy(() => import('pages/account/newAuth/Payments/PaymentFail'));

// App
const Downloads = React.lazy(() => import('pages/downloads/Downloads'));
const Uploads = React.lazy(() => import('pages/uploads/Uploads'));

// pages
// const MapBoxDisplay = React.lazy(() => import('../components/mapbox/MapBoxDisplay'));
const Dashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const Profile = React.lazy(() => import('pages/profile/Profile'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));
const SessionExpired = React.lazy(() => import('pages/error/SessionExpired'));
const RanchMetrics = React.lazy(() => import('pages/RanchMetrics/RanchMetrics'));
const GrazingData = React.lazy(() => import('pages/uploads/GrazingData'));
const SoilData = React.lazy(() => import('pages/uploads/SoilData'));
const ShapeFiles = React.lazy(() => import('pages/uploads/ShapeFiles'));

// Onboarding
const CreateRanch = React.lazy(() => import('pages/onboarding/CreateRanch'));
const JoinRanchRequest = React.lazy(() => import('pages/onboarding/JoinRanchRequest'));
const EditRanch = React.lazy(() => import('pages/onboarding/shapefiles/EditRanch'));

// Other
const PageNotFound = React.lazy(() => import('pages/error/PageNotFound'));

const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const Lander = React.lazy(() => import('pages/Lander'));

const loading = () => <div className=""></div>;

// Layouts

// const AuthLayout = React.lazy(() => import('layouts/WSEAuth/AuthLayout'));

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Sentry.ErrorBoundary fallback={SomethingWentWrong}>
            <Component />
        </Sentry.ErrorBoundary>
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { groupTrees, user, isSuperAdmin } = useAuth();
    const { groupTreeData, currentRanch } = useData();

    function ranchSpecificProtection(component: React.LazyExoticComponent<() => JSX.Element>) {
        // Could show super admin what users see?
        if (isSuperAdmin) return <LoadComponent component={component} />;
        // console.log('currentRanch?.accountStatus', currentRanch?.accountStatus);
        // console.log('groupTreeData', groupTreeData);

        if (groupTrees?.length === 0) {
            return <NoRanches />;
        } else if (currentRanch?.accountStatus === 'DISABLED') {
            return <RanchDisabled />;
        } else if (currentRanch?.paymentStatus === 'INCOMPLETE') {
            return <PaymentRequired />;
        } else if (currentRanch?.accountStatus === 'ONBOARDING' || groupTreeData?.length === 1) {
            // This means there is a ranch but no pastures
            return <OnboardingNotification />;
        } else {
            return <LoadComponent component={component} />;
        }
    }

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                // {
                //     path: 'account',
                //     children: [
                { path: 'login', element: <LoadComponent component={SignInCustom} /> },
                { path: 'signup', element: <LoadComponent component={SignUp} /> },
                // { path: 'register', element: <LoadComponent component={Register} /> },
                // { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                { path: 'forgot-password', element: <LoadComponent component={ForgotPassword} /> },
                // { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                { path: 'logout', element: <LoadComponent component={Logout} /> },
                //     ],
                // },
                {
                    path: 'something-went-wrong',
                    element: <SomethingWentWrong />,
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Lander} />,
                },
                {
                    path: 'sessionExpired',
                    element: <LoadComponent component={SessionExpired} />,
                },
            ],
        },
        // {
        //     // Payment Routes
        //     path: '/',
        //     element: <PrivateRoute component={DefaultLayout} />,
        //     children: [{ path: 'productSelect', element: <LoadComponent component={ProductSelect} /> }],
        // },
        {
            path: '/',
            element: <PrivateRoute component={Layout} />,
            children: [
                {
                    path: 'paymentSuccess',
                    element: <LoadComponent component={paymentSuccess} />,
                },
                {
                    path: 'paymentFail',
                    element: <LoadComponent component={paymentFail} />,
                },
            ],
        },
        // Onboarding Routes
        {
            path: '/onboarding',
            element: <PrivateRoute component={OnboardingLayout} />,
            children: [
                {
                    path: '/onboarding/createRanch',
                    element: <LoadComponent component={CreateRanch} />,
                },
                {
                    path: '/onboarding/joinRanchRequest',
                    element: <LoadComponent component={JoinRanchRequest} />,
                },
            ],
        },
        {
            path: '/onboarding/admin',
            element: <PrivateRoute roles={['SUPER_ADMIN']} component={Layout} />,
            children: [
                {
                    path: '/onboarding/admin/editRanch',
                    element: <LoadComponent component={EditRanch} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute component={Layout} />,
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Lander} />,
                },
                {
                    path: 'profile',
                    element: <LoadComponent component={Profile} />,
                },
            ],
        },

        {
            // Subscriber Routes
            path: '/',
            element: (
                <PrivateRoute
                    // roles={['Subscriber']}
                    notAllowedToast="You must upgrade to a Subscriber account to access this page!"
                    component={Layout}
                />
            ),
            children: [
                {
                    path: 'dashboard',
                    element: ranchSpecificProtection(Dashboard),
                },
                {
                    path: 'metrics',
                    element: ranchSpecificProtection(RanchMetrics),
                },
                {
                    path: 'downloads',
                    element: ranchSpecificProtection(Downloads),
                },
                {
                    path: 'uploads',
                    children: [
                        {
                            path: '',
                            element: ranchSpecificProtection(Uploads),
                        },
                        {
                            path: 'grazingData',
                            element: ranchSpecificProtection(GrazingData),
                        },
                        {
                            path: 'shapeFiles',
                            element: ranchSpecificProtection(ShapeFiles),
                        },
                        {
                            path: 'soilData',
                            element: ranchSpecificProtection(SoilData),
                        },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <LoadComponent component={PageNotFound} />,
        },
    ]);
};

export { AllRoutes };
