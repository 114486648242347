import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRedux } from 'hooks';
import { changeBodyAttribute } from 'utils';
import { ErrorBoundary } from 'react-error-boundary';
import SomethingWentWrong from 'pages/error/SomethingWentWrong';

const loading = () => <div className="text-center m-auto">Loading...</div>;

type DefaultLayoutProps = {};

const DefaultLayout = (props: DefaultLayoutProps) => {
    const { appSelector } = useRedux();

    const { layoutColor } = appSelector((state) => ({
        layoutColor: state.Layout.layoutColor,
    }));

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);

    // console.log('IN DEFAULT LAYOUT!!');

    return (
        <Suspense fallback={loading()}>
            {/* <ErrorBoundary fallbackRender={SomethingWentWrong}> */}
            <Outlet />
            {/* </ErrorBoundary> */}
        </Suspense>
    );
};
export default DefaultLayout;
