import Routes from 'routes/Routes';

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';
import { AuthProvider } from './context/AuthContext';
import { MetadataProvider } from './context/MetadataContext';
import { DataProvider } from './context/DataContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Toaster } from 'react-hot-toast';
import { DataObservationProvider } from './context/DataObservationsContext';
import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from 'routes';

// Amplify.configure(awsconfig);

const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                {/*<MetadataProvider>*/}
                <DataProvider>
                    <DataObservationProvider>
                        <AllRoutes />
                    </DataObservationProvider>
                </DataProvider>
                {/*</MetadataProvider>*/}
                <Toaster position="top-right" />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default App;
