import React from 'react';

const NoRanches = () => {
    return (
        <div className="text-center mt-3">
            <h2>No Data Found! You must be subscribed to a Ranch to access this page. </h2>
            <h3>
                Please reach out to your ranch owner or manager to request access to the ranch.
                <br />
                If you think this is a mistake please reach out to{' '}
                <a href="mailto: regen@wsestaff.org">regen@wsestaff.org </a>
            </h3>
        </div>
    );
};

export default NoRanches;
