import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { APICore } from 'helpers/api/apiCore';
import { useUser } from 'hooks';
import { useAuth } from '../context/AuthContext';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useData } from 'context/DataContext';

type PrivateRouteProps = {
    component: React.ComponentType;
    children?: React.ReactNode;
    roles?: [RanchUserRoll];
    notAllowedToast?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, notAllowedToast }: PrivateRouteProps) => {
    // let location = useLocation();

    const { isAuthenticated, isSuperAdmin } = useAuth();

    const { ranchUserRoll } = useData();

    // console.log('USER ROLE IN PRIVATE ROUTE', ranchUserRoll);

    if (!isAuthenticated) {
        /**
         * not logged in so redirect to login page with the return url
         */
        console.log('Unauthenticated, redirect..');

        return <Navigate to={'/login'} replace />;
    }

    if (!isSuperAdmin && roles) {
        // Check roles
        if (!ranchUserRoll || !roles.includes(ranchUserRoll.role)) {
            if (notAllowedToast) {
                toast.error(notAllowedToast);
            }
            return <Navigate to={'/'} replace />;
        }
    }
    return (
        <RouteComponent>
            <Outlet />
        </RouteComponent>
    );
};

export default PrivateRoute;
