import { createContext, Dispatch, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getDataObservations } from '../services/DataObservations';
import { useData } from './DataContext';
import { calculateSDAMonthly, getCleanPastureName } from 'helpers/GrazeDataHelpers';
import { keyClean } from 'helpers/dynamoKeyFunctions';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { datesHaveOverlap, getDateTime, MAX_DATE } from 'helpers/dateAndTimeHelpers';
import { useToggle } from 'hooks';

export type DataObservationsContext = {
    setFilterYear: (year: DateTime) => void;
    filterYear: DateTime;
    fetchDataset: (dataset: string) => Promise<void>; // this will be of type DataObservationDataset
    ranchGrazeData: GrazingData[] | null;
    currentBranchUnfilteredGrazeData: GrazingData[] | null;
    currentBranchGrazeData: GrazingData[] | null;

    // ranchPastureInfo: PastureInfo[] | null;
    // currentBranchPastureInfo: PastureInfo | null;

    ranchGrazingPlan: GrazingData[] | null;
    currentBranchGrazingPlan: GrazingData[] | null;

    ranchSoilData: SoilData[] | null;
    currentSoilData: SoilData[] | null;

    sdaMonthly: SDAMonthly[] | null; // Not filtered by Year
    isMenuOpened: boolean;
    toggleMenu: () => void;
};

const DataObservationsContext = createContext<undefined | DataObservationsContext>(undefined);

const useDataObservation = () => {
    const data = useContext(DataObservationsContext);
    if (!data) {
        throw new Error('useData must be used within DataProvider');
    }
    return data;
};

type DatasetDependencies = any;

type DatasetMap = {
    [name: string]: { setter: Dispatch<any>; calculatedFunction?: any; dependencies?: DatasetDependencies };
};

const DataObservationProvider: React.FC = ({ children }: PropsWithChildren<any>) => {
    const { currentRoot, currentBranch, dataObservationsMetadata, herds, pastures, currentPointLocation } = useData();
    const { refreshAuthToken, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [filterYear, setFilterYear] = useState<DateTime>(MAX_DATE);

    // Datasets
    const [ranchGrazeData, setRanchGrazeData] = useState<null | GrazingData[]>(null);
    // const [grazeSeason, setGrazeSeason] = useState<null | GrazeSeason[]>(null);
    // const [ranchPastureInfo, setRanchPastureInfo] = useState<null | PastureInfo[]>(null);
    const [ranchGrazingPlan, setRanchGrazingPlan] = useState<null | GrazingData[]>(null);
    const [ranchSoilData, setRanchSoilData] = useState<null | SoilData[]>(null);
    const [isMenuOpened, toggleMenu] = useToggle();

    // Fetch Datasets

    const datasetMap: DatasetMap = {
        // 'sda-monthly': {
        //     setter: setSdaMonthly,
        //     calculatedFunction: calculateSDAMonthly,
        //     dependencies: [pastureInfo, grazeData, herds],
        // },
        // 'pasture-info': { setter: setRanchPastureInfo },
        'grazing-data': { setter: setRanchGrazeData },
        // 'graze-seasons': setGrazeSeason,
        'grazing-plan': { setter: setRanchGrazingPlan },
        'soil-data': { setter: setRanchSoilData },
    };

    const fetchDataset = async (dataset: string) => {
        if (currentRoot) {
            const metadata = await dataObservationsMetadata;
            try {
                const datasetMetadata = datasetMap[dataset];
                const dob = await getDataObservations(currentRoot, dataset, metadata);
                if (dob) {
                    // console.log('dob', dob);
                    const datasetSetter = datasetMetadata['setter'];
                    datasetSetter(dob);
                }
            } catch (err) {
                // console.log(dataset);
                console.error(err);
                throw err;
            }
        }
    };

    const fetchDatasetError = (err: any) => {
        console.log('FetchDatasetError', err);
        if (err.message === 'Session expired') {
            console.log('Found expired token!');

            navigate('/sessionExpired');
        }
    };

    const updateRootDatasets = async () => {
        // console.log('updating root datasets for currentRoot: ', currentRoot);
        // await fetchDataset('pasture-info').catch((err) => {
        //     fetchDatasetError(err);
        // });
        await fetchDataset('grazing-data').catch((err) => {
            fetchDatasetError(err);
        });
        await fetchDataset('grazing-plan').catch((err) => {
            fetchDatasetError(err);
        });
        await fetchDataset('soil-data').catch((err) => {
            fetchDatasetError(err);
        });

        await refreshAuthToken();
    };

    // useEffect(() => {
    //     console.log('In the update root db effect ');
    //     if (currentRoot && isAuthenticated) {
    //         updateRootDatasets();
    //     }
    // }, []);

    useEffect(() => {
        // console.log('currentRoot', currentRoot);
        // console.log('isAuthenticated', isAuthenticated);
        if (currentRoot && isAuthenticated) {
            updateRootDatasets();
        }
    }, [currentRoot, isAuthenticated]);

    // const resetDatasets = () => {
    //     for (const key in datasetMap) {
    //         const datasetMetadata = datasetMap[key];
    //         const datasetSetter = datasetMetadata['setter'];
    //         datasetSetter(null);
    //     }
    // };

    // Current Branch Datasets

    const currentBranchUnfilteredGrazeData = useMemo(() => {
        if (ranchGrazeData && currentBranch) {
            return ranchGrazeData.filter((gd) => {
                // console.log('dob in Pasture: ', gd);
                return getCleanPastureName(gd) === keyClean(currentBranch?.name);
            });
        }
        return null;
    }, [ranchGrazeData, currentBranch]);

    const currentBranchGrazeData = useMemo(() => {
        if (currentBranchUnfilteredGrazeData) {
            return currentBranchUnfilteredGrazeData.filter((gd) => {
                // console.log('dob in Pasture: ', gd);
                const dateOverlap = filterYear.equals(MAX_DATE)
                    ? true
                    : datesHaveOverlap(
                          getDateTime(gd.dateIn)!,
                          getDateTime(gd.dateOut) || DateTime.now(),
                          filterYear.set({ day: 1, month: 1 }),
                          filterYear.set({ day: 31, month: 12 })
                      );
                return dateOverlap;
            });
        }
        return null;
    }, [currentBranchUnfilteredGrazeData, filterYear]);

    const currentBranchGrazingPlan = useMemo(() => {
        if (ranchGrazingPlan && currentBranch) {
            return ranchGrazingPlan.filter((gp) => getCleanPastureName(gp) === keyClean(currentBranch?.name));
        }
        return null;
    }, [ranchGrazingPlan, currentBranch]);

    const currentSoilData = useMemo(() => {
        if (ranchSoilData && currentPointLocation) {
            return ranchSoilData.filter((sd) => sd.sampleGeohash === currentPointLocation.geohash);
        }
        return null;
    }, [ranchSoilData, currentPointLocation]);

    // const currentBranchPastureInfo = useMemo(() => {
    //     // console.log('updating currentBranchPastureInfo');

    //     if (ranchPastureInfo && currentBranch) {
    //         // console.log('currentBranch: ', currentBranch);
    //         console.log('ranchPastureInfo', ranchPastureInfo);
    //         const foundPastureInfo = ranchPastureInfo?.find((pi) => {
    //             console.log('pasture info Check: ', pi);
    //             return getCleanPastureName(pi) === keyClean(currentBranch?.name);
    //         });
    //         // console.log('foundPastureInfo', foundPastureInfo);
    //         return foundPastureInfo || null;
    //     }
    //     return null;
    // }, [ranchPastureInfo, currentBranch]);

    // Calculate Metrics

    // NOTE: This is not filtered by year
    const sdaMonthly = useMemo(() => {
        if (currentBranchUnfilteredGrazeData && herds && currentBranch && pastures) {
            if (currentBranchUnfilteredGrazeData.length > 0) {
                // NOTE: calculateSDAMonthly can calculate for multiple pastures at a time.. thus sending as array
                const sda = calculateSDAMonthly(currentBranchUnfilteredGrazeData, pastures, herds);
                // console.log('sda', sda);
                return sda;
            }
        }

        return null;
    }, [currentBranchUnfilteredGrazeData, pastures, herds, currentBranch]);

    return (
        <DataObservationsContext.Provider
            value={{
                setFilterYear,
                filterYear,

                fetchDataset,
                ranchGrazeData,
                currentBranchUnfilteredGrazeData,
                currentBranchGrazeData,

                // ranchPastureInfo,
                // currentBranchPastureInfo,

                ranchGrazingPlan,
                currentBranchGrazingPlan,

                ranchSoilData,
                currentSoilData,

                sdaMonthly,
                isMenuOpened,
                toggleMenu,
            }}>
            {children}
        </DataObservationsContext.Provider>
    );
};

export { useDataObservation, DataObservationProvider };
