import { useData } from 'context/DataContext';
import React from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { createCheckoutForIncompleteRanch } from 'services/payments';

const PaymentRequired = () => {
    const { ranchUserRoll, currentRanch } = useData();

    const canPay = ['OWNER', 'RANCH_ADMIN'].includes(ranchUserRoll?.role || '');

    async function pay() {
        if (!currentRanch) {
            toast.error('Error getting Ranch, Please logout and try again');
            return;
        }
        const res = await toast
            .promise(createCheckoutForIncompleteRanch(currentRanch.name), {
                loading: 'Loading...',
                success: 'Redirecting to Stripe...',
                error: 'Error getting payment session',
            })
            .then((res) => {
                console.log(res);
                window.location.href = res.url;
            })
            .catch((e) => {
                console.log('error: ', e);
            });
    }

    return (
        <div className={'text-center m-4'}>
            <h2>Payment Incomplete</h2>
            {canPay ? (
                <h3>
                    Payment for {currentRanch?.name} is incomplete. Please click pay now below to activate your ranch:{' '}
                    <Button variant="outline-secondary" onClick={pay}>
                        Activate Ranch
                    </Button>
                </h3>
            ) : (
                <h3>
                    Payment for {currentRanch?.name} is incomplete. Please have the ranch owner or admin complete
                    payment to continue using the service.
                </h3>
            )}
        </div>
    );
};

export default PaymentRequired;
