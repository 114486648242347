import { useAuth } from 'context/AuthContext';
import { Navigate } from 'react-router-dom';

const Root = () => {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to={'/login'} replace />;
        // } else if (userRoll === 'Free') {
        //     return <Navigate to={'/home'} replace />;
    } else {
        return <Navigate to={`/dashboard`} />;
    }
};

export default Root;
