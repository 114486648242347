export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'home',
        label: 'Home',
        isTitle: false,
        icon: 'uil-home-alt',
        url: '/home',
    },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-location-point',
        url: '/dashboard',
    },
    {
        key: 'metrics',
        label: 'Ranch Metrics',
        isTitle: false,
        icon: 'uil-chart-line',
        url: '/metrics',
    },
    {
        key: 'downloads',
        label: 'Downloads',
        isTitle: false,
        icon: 'uil-file-download',
        url: '/downloads',
    },
    {
        key: 'uploads',
        label: 'Uploads',
        isTitle: false,
        icon: 'uil-file-upload',
        url: '/uploads',
    },
    {
        key: 'profile',
        label: 'Profile',
        isTitle: false,
        icon: 'uil-users-alt',
        url: '/profile',
    },
    // {
    //     key: 'grouptreeNav',
    //     label: 'Ranches',
    //     isTitle: false,
    //     icon: 'uil-folder-plus',
    //     children: [
    //         {
    //             key: 'flying-d',
    //             label: 'Flying D Ranch',
    //             url: '/',
    //             parentKey: 'grouptreeNav',
    //             children: [
    //                 {
    //                     key: 'flying-d-mu',
    //                     label: 'Flying D',
    //                     url: '/',
    //                     parentKey: 'flying-d',
    //                 },
    //                 {
    //                     key: 'green-mu',
    //                     label: 'Green',
    //                     url: '/',
    //                     parentKey: 'flying-d',
    //                 },
    //             ],
    //         },
    //         {
    //             key: 'j-bar-l',
    //             label: 'J Bar L Ranch',
    //             url: '/',
    //             parentKey: 'grouptreeNav',
    //             children: [
    //                 {
    //                     key: 'crazy-d-mu',
    //                     label: 'Crazy D',
    //                     url: '/',
    //                     parentKey: 'j-bar-l',
    //                 },
    //                 {
    //                     key: 'upper-centennial-mu',
    //                     label: 'Upper Centennial',
    //                     url: '/',
    //                     parentKey: 'j-bar-l',
    //                 },
    //             ],
    //         },
    //     ],
    // },
];

export { MENU_ITEMS };
