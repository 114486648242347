import { useAuth } from 'context/AuthContext';
import { useData } from 'context/DataContext';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OnboardingNotification = () => {
    const navigate = useNavigate();
    const { user, isSuperAdmin } = useAuth();

    console.log('isSuperAdmin', isSuperAdmin);

    return (
        <>
            {isSuperAdmin && (
                <Row>
                    <h2 className="m-3"> New Ranch!</h2>
                    <div className="text-center mt-4">
                        <h3>
                            {' '}
                            Click here to begin onbording this ranch:{' '}
                            <Button onClick={() => navigate('/onboarding/admin/addShapefiles')}>Onboard</Button>
                        </h3>
                    </div>
                    <div className="mt-2 mx-3">Below is what non-super users will see:</div>
                </Row>
            )}
            <div className={`text-center m-4 ${isSuperAdmin && 'border'}`}>
                <h2>
                    Great! Your account is all set up. A notification has been sent to WSE staff who will reach out to{' '}
                    {user?.email} to help onboard.{' '}
                </h2>
                <h3 className="mt-5">Until then please make sure you have these things ready:</h3>
                <div className="justify-content-center d-flex">
                    <Col sm="4" className="">
                        <ul className="fs-3">
                            <li>Shapefiles for your ranch</li>
                            <li>Historical Grazing Data</li>
                            <li>Herd information (size, average weight etc.)</li>
                        </ul>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default OnboardingNotification;
