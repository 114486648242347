import { API, graphqlOperation } from 'aws-amplify';
// import apiClient, { appSyncClient } from './appSyncClient';
import { useData } from '../context/DataContext';
import { buildCleanObject, keyBuilder, keyClean } from '../helpers/dynamoKeyFunctions';
import { DateTime } from 'luxon';
import axiosInstance from './apiCore';
import { GrazeEvent } from 'pages/uploads/components/GrazeDataUploadTable';

export const getAllDataObservations = async (groupTreeRootName: string) => {
    const pk = groupTreeRootName.toLowerCase().replaceAll(' ', '-');

    // NOTE: removed cells
    const body = {
        pk,
    };

    const res = await axiosInstance.post('getDataObservations', body);
    // console.log('res: ', data);
    return res.data;
};

export const getDataObservations = async (
    groupTreeRootName: string,
    inDatasetName: string,
    dataObservationsMetadata: Metadata[],
    inSkDetails: string = ''
) => {
    const pk = keyClean(groupTreeRootName);
    const datasetName = keyClean(inDatasetName);
    const skDetails = keyClean(inSkDetails);

    // const dataset = dataObservationsMetadata.find((md) => md.name == datasetName);
    // if (!dataset) {
    //     throw new Error('Dataset Not Found!');
    // }
    // const attributes = dataset.attributes.map((a) => {
    //     return a.name;
    // });

    const body = {
        pk: pk,
        sk: `${datasetName}#${skDetails}`,
    };

    const res = await axiosInstance.post('getDataObservations', body);
    // console.log('res: ', res);
    return res.data;
};

export const deleteDataObservation = async (dobs: DataObservation, metadata: Metadata) => {
    const pk = keyBuilder(metadata['partition_key']!, dobs);

    // console.log('delete body: ', body);
    try {
        const data = await axiosInstance.post('deleteDataObservation', { pk, sk: dobs.sk });
        // console.log('res: ', data);
        return data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const bulkUploadGrazingData = async (data: GrazeEvent[]) => {
    try {
        const res = await axiosInstance.post('bulkUploadGrazingData', { grazeEvents: data });
        return res;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

// Soil Data

export async function getSoilData(ranchName: string) {
    const res = await axiosInstance.post('getDataObservations', { pk: ranchName, sk: 'soil-data' });
    return res.data;
}
