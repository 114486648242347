import axiosInstance from './apiCore';

export async function createPortalSession(ranchName: string) {
    try {
        const response = await axiosInstance.post('/createPortalSession', { ranchName });
        return response.data;
    } catch (error) {
        console.error('Issues creating portal session', error);
        throw error;
    }
}

export async function createUnpaidRanch(ranchName: string, ranchSize: string) {
    try {
        const response = await axiosInstance.post('/admin/createUnpaidRanch', {
            ranchName,
            ranchSize,
        });
        return response.data;
    } catch (error) {
        console.error('Issues creating unpaid ranch', error);
        throw error;
    }
}

export async function makeRanchGoldenTicket(ranchName: string) {
    try {
        const response = await axiosInstance.post('/admin/makeRanchGoldenTicket', {
            ranchName,
        });
        return response.data;
    } catch (error) {
        console.error('Issues making ranch golden ticket', error);
        throw error;
    }
}

export async function removeGoldenTicket(ranchName: string) {
    try {
        const response = await axiosInstance.post('/admin/removeGoldenTicket', {
            ranchName,
        });
        return response.data;
    } catch (error) {
        console.error('Issues removing golden ticket', error);
        throw error;
    }
}

export async function createCheckoutSession(ranchName: string, ranchSize: string) {
    try {
        const response = await axiosInstance.post('/createCheckoutSession', { ranchName, ranchSize });
        return response.data;
    } catch (error) {
        console.error('Issues creating checkout session', error);
        throw error;
    }
}

export async function confirmNewSubscription(sessionId: string) {
    try {
        const response = await axiosInstance.post('/confirmSubscription', {
            sessionId,
        });
        console.log('response', response);
        return response.data;
    } catch (error) {
        console.error('Issues confirming subscription', error);
        throw error;
    }
}

export async function updateSubscription(ranchName: string) {
    try {
        const response = await axiosInstance.post('/updateSubscription', {
            ranchName,
        });

        console.log('response', response);
        return response.data;
    } catch (error) {
        console.error('Issues updating subscription', error);
        throw error;
    }
}

export async function createCheckoutForIncompleteRanch(ranchName: string) {
    try {
        const response = await axiosInstance.post('/createCheckoutForIncompleteRanch', {
            ranchName,
        });
        return response.data;
    } catch (error) {
        console.error('Issues creating checkout for incomplete ranch', error);
        throw error;
    }
}
