import { useState, useEffect, useCallback } from 'react';
import { useEventListener } from 'usehooks-ts';
import { DateTime } from 'luxon';

// Helper function to get nested property
const getNestedObject = (nestedObj: any, pathArr: string[]): any => {
    return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
};

// Helper function to set nested property
const setNestedObject = (nestedObj: any, pathArr: string[], value: any): any => {
    let obj = nestedObj;
    const lastKey = pathArr.pop();

    pathArr.forEach((key) => {
        if (!obj[key]) obj[key] = {};
        obj = obj[key];
    });

    obj[lastKey!] = value;
    return nestedObj;
};

function useNestedLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
    const readValue = (): T => {
        try {
            const pathArr = key.split('.');
            const baseKey = pathArr[0];
            const nestedPath = pathArr.slice(1);
            const item = window.localStorage.getItem(baseKey);
            const parsedItem = item ? JSON.parse(item) : {};
            const nestedItem = getNestedObject(parsedItem, nestedPath);
            return nestedItem !== undefined ? nestedItem : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    };

    const [storedValue, setStoredValue] = useState<T>(readValue);

    const setValue = (value: T) => {
        try {
            const pathArr = key.split('.');
            const baseKey = pathArr[0];
            const nestedPath = pathArr.slice(1);
            const oldValue = JSON.parse(window.localStorage.getItem(baseKey) || '{}');
            const newValue = setNestedObject(oldValue, nestedPath, value);
            window.localStorage.setItem(baseKey, JSON.stringify(newValue));
            setStoredValue(value);
            window.dispatchEvent(new Event('local-storage'));
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error);
        }
    };

    useEffect(() => {
        setStoredValue(readValue());
    }, [key]);

    const handleStorageChange = useCallback(() => {
        setStoredValue(readValue());
    }, [readValue]);

    useEventListener('local-storage', handleStorageChange);

    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [handleStorageChange]);

    return [storedValue, setValue];
}

export default useNestedLocalStorage;
