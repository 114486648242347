import axios from 'axios';
import { LS_TOKEN_KEY } from './constants';
import { keyCompare } from 'helpers/dynamoKeyFunctions';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_REST_URL,
});

// axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use((config) => {
    config.headers = { 'Content-Type': 'application/json' };

    const lsToken = localStorage.getItem(LS_TOKEN_KEY);
    // console.log('lsToken', lsToken);
    if (lsToken && lsToken !== 'null') {
        const token = JSON.parse(lsToken);
        config.headers['Authorization'] = token.idToken;
    }

    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        console.log('FOUND ERROR: ', error);
        console.log('error.response', error.response);
        // console.log('test', error.toJSON().message === 'Network error');

        if (keyCompare(error.toJSON().message, 'Network Error')) {
            console.log('Network Error!');
            window.location.href = '/sessionExpired';
        }

        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            if (error.response.data.message) {
                console.error(error.response.data.message);
                return Promise.reject(error.response.data.message);
            } else {
                window.location.href = '/sessionExpired';
            }
        } else {
            if (!error.response || !error.response.status) {
                return Promise.reject('Ops! something went wrong!');
            }
            switch (error.response.status) {
                case 400:
                    message = error.response.data;
                    break;
                case 401:
                    console.log('reload!');
                    message = 'Invalid credentials';
                    window.location.href = '/sessionExpired';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

export default axiosInstance;
