import { DateTime, Interval } from 'luxon';
import { keyClean, keyCompare } from './dynamoKeyFunctions';
import { MAX_DATE, datesHaveOverlap, getDateTime } from './dateAndTimeHelpers';
import { round } from './numbers';
import { CellDetail, GrazingDataFormInput } from 'components/DataObservations/GrazingDataForm';
import { string } from 'yup';

export const getFirstDateTimeIn = (grazeData: GrazingData[]) => {
    if (grazeData.length < 1) {
        return DateTime.now();
    }
    const firstEvent = grazeData.reduce((prev, curr) => {
        const curDateTime = getDateTime(curr.dateIn);
        const prevDateTime = getDateTime(prev.dateIn);
        // Never take new that is null
        if (!curDateTime || !curDateTime.isValid) return prev;
        // Take non null always over null
        if (!prevDateTime || !prevDateTime.isValid) return curr;
        return prevDateTime < curDateTime ? prev : curr;
    });
    // console.log('first Year: ', firstYear);
    return getDateTime(firstEvent.dateIn);
};

export const getLastDateTimeOut = (grazeData: GrazingData[]) => {
    if (grazeData.length < 1) {
        return null;
    }
    const LastEvent = grazeData.reduce((prev, curr) => {
        const curDateTime = getDateTime(curr.dateOut) || DateTime.now();
        const prevDateTime = getDateTime(prev.dateOut) || DateTime.now();
        // console.log('curDateTime', curDateTime?.toISO());
        // console.log('prevDateTime', prevDateTime?.toISO());
        // Never take new that is null
        if (!curDateTime || !curDateTime.isValid) {
            // console.log('!curDateTime', prev);
            return prev;
        } else if (!prevDateTime || !prevDateTime.isValid) {
            // Take non null always over null
            // console.log('!prevDateTime', curr);
            return curr;
        } else {
            // console.log(`prevDateTime > curDateTime`, prevDateTime > curDateTime);
            return prevDateTime > curDateTime ? prev : curr;
        }
    });

    return getDateTime(LastEvent.dateOut);
};

export const calculateRecoveryDays = (grazeData: GrazingData[]) => {
    // console.log('in Calc Recovery days: ', grazeData);
    if (grazeData.length > 0) {
        const lastDateTime = getLastDateTimeOut(grazeData);
        if (!lastDateTime) return 0;
        const restDays = DateTime.now().diff(lastDateTime, 'days')['days'];
        return Math.round(restDays);
    }
    return null;
};

// type SDA = {
// 	SDAMonth: string,
// 	grazeDays: number,
// 	stockdaysPerAcre: number,
// 	grazeDates: number[]
// 	month?: number
// 	year?: number
// 	yearMonthDateTime?: DateTime
// }

type SDAKey = {
    [SDAMonth: string]: SDAMonthly;
};

const yearMonthFromNumbers = (year: number, month: number): string => {
    const monthString = month < 10 ? `0${month}` : month.toString();
    const yearString = year.toString();
    return `${yearString}-${monthString}`;
};

function onlyUnique(value: number, index: number, array: number[]) {
    return array.indexOf(value) === index;
}

function range(size: number, startAt = 0) {
    let array = [];
    for (let i = 0; i < size; i++) {
        array[i] = i + startAt;
    }
    return array;
}

export const calculatePriorYearSDA = (sdaMonthly: SDAMonthly[] | null): number => {
    // console.log('sdaMonthly in calc prior year', sdaMonthly);
    if (sdaMonthly && sdaMonthly.length > 0) {
        const lastYear = DateTime.now().minus({ years: 1 }).year;
        return sdaMonthly.reduce((sdaAccumulator, curentVal) => {
            if (curentVal.year === lastYear) {
                return sdaAccumulator + curentVal.stockdaysPerAcre;
            }
            return sdaAccumulator;
        }, 0);
    }

    return 0;
};

export const getLastGrazeSDAMonthly = (sdaMonthly: SDAMonthly[] | null) => {
    if (sdaMonthly && sdaMonthly.length > 0) {
        const sortedSDAs = sdaMonthly.sort((a, b) => {
            if (a.yearMonthDateTime < b.yearMonthDateTime) return -1;
            if (a.yearMonthDateTime < b.yearMonthDateTime) return 1;
            return 0;
        });
        // console.log('sortedSDAs', sortedSDAs);
    }
    return null;
};

export const getCleanPastureName = (dob: BaseDataObservation) => {
    // console.log('dob', dob);
    if (dob.pastureName) {
        return keyClean(dob.pastureName);
    }
    if (dob.name) {
        return keyClean(dob.name);
    }
    if (dob.sk) {
        // console.log('Checking Group Path: ', dob.groupPath);
        // console.log('currentBranch: ', currentBranch);
        const groupPathSplit = dob.sk.split('#');
        const leaf = groupPathSplit[groupPathSplit.length - 2];
        return keyClean(leaf);
    }
};

export const PastureSDAYearly = (sdaMonthly: SDAMonthly[] | null): SDAYearly[] => {
    if (sdaMonthly && sdaMonthly.length > 0) {
        let sdaYearly: { [year: string]: SDAYearly } = {};
        sdaMonthly.forEach((sda) => {
            const year = sda.SDAMonth.split('-')[0];
            const month = sda.SDAMonth.split('-')[1];

            const grazeDates = sda.grazeDates.map(
                (day) => DateTime.fromObject({ day, month: Number(month), year: Number(year) }).ordinal
            );
            if (year in sdaYearly) {
                sdaYearly[year].grazeDays += sda.grazeDays;
                sdaYearly[year].stockdaysPerAcre += sda.stockdaysPerAcre;
                sdaYearly[year].grazeDates = sdaYearly[year].grazeDates.concat(grazeDates);
            } else {
                sdaYearly[year] = {
                    grazeDays: sda.grazeDays,
                    stockdaysPerAcre: sda.stockdaysPerAcre,
                    grazeDates: grazeDates,
                    year: Number(year),
                };
            }
        });
        return Object.values(sdaYearly);
    }
    return [];
};

export const calculateYearlyTotalStockDayPerAcre = (
    grazingData: GrazingData[],
    pastures: Pasture[],
    herds: Herd[]
): SDAYearly[] => {
    const start = getFirstDateTimeIn(grazingData);
    const end = getLastDateTimeOut(grazingData) || DateTime.now();
    if (!start) {
        console.error('No start date found! Exit SDA calculation');
        return [];
    }

    let sdaKey: { [year: string]: SDAYearly } = {};
    const endDateTime = DateTime.now() > end ? DateTime.now() : end;

    // 	Make 0s array
    for (let year = start.year; year <= endDateTime.year; year++) {
        sdaKey[year] = {
            grazeDays: 0,
            stockdaysPerAcre: 0,
            grazeDates: [],
            year: year,
        };
    }

    grazingData.forEach((grazeEvent) => {
        const dateIn = DateTime.fromISO(grazeEvent.dateIn);
        const dateOut = DateTime.fromISO(grazeEvent.dateOut);

        // 	For each year event is a part of
        for (let year = dateIn.year; year <= dateOut.year; year++) {
            const yearString = year.toString();
            const startDay = year === dateIn.year ? dateIn : DateTime.local(year, 1, 1);
            const endDay = year === dateOut.year ? dateOut : DateTime.local(year, 12, 31);

            const days = endDay.day - startDay.day + 1;
            // console.log('days', days);

            // 	get acres (splits or base pasture)
            let acres = grazeEvent.cellSize || grazeEvent.splitSize || -1;
            if (acres === -1 || acres === null || isNaN(acres)) {
                const pasture = pastures.find((pi) => {
                    // console.log('pi', pi);
                    // console.log('grazeEvent', grazeEvent);
                    return getCleanPastureName(pi) === getCleanPastureName(grazeEvent);
                });
                if (!pasture) {
                    console.error('Pasture not found!');
                    return [];
                }
                // console.log('pasture', pasture);
                acres = pasture.acres;
            }
            // console.log('acres', acres);
            // 	Get rancher weight
            // console.log('looking for herd: ', grazeEvent.herdName);
            // console.log('looking in ', herds);
            const herd = herds.find((h) => keyCompare(h.name, grazeEvent.herdName));
            if (!herd || !herd.animalConversion) {
                console.error('Herd not found!');
                return [];
            }
            // console.log('herd', herd);

            // 	calc SDAs
            const stockDensity = grazeEvent.herdSize * herd.animalConversion;

            const sda = (stockDensity * days) / acres;
            // console.log('stockDensity', stockDensity);
            // console.log('days', days);
            // console.log('acres', acres);

            const currentGrazeDays = range(days, startDay.ordinal);
            // console.log('currentGrazeDays', currentGrazeDays);
            const allYearGrazeDays = sdaKey[yearString].grazeDates.concat(currentGrazeDays).filter(onlyUnique);

            // console.log('allMonthGrazeDays', allMonthGrazeDays);
            // console.log('SDA', sda);
            // console.log('sdaKey[SDAMonth]', sdaKey[SDAMonth]);
            sdaKey[yearString].grazeDates = allYearGrazeDays;
            sdaKey[yearString].stockdaysPerAcre += sda;
        }
    });

    // Pull Unique Graze Days
    Object.keys(sdaKey).forEach((key) => {
        sdaKey[key].grazeDates = sdaKey[key].grazeDates.filter(onlyUnique);
        sdaKey[key].grazeDays = sdaKey[key].grazeDates.length;
    });

    // console.log('sdas ', Object.values(sdaKey));
    return Object.values(sdaKey);
};

type stockDensityEvent = {
    stockDensityAcre: number;
    grazeDates: number[];
    grazeLength: number;
};

export const calculateYearlyWeightedStockPerAcre = (grazingData: GrazingData[], pastures: Pasture[], herds: Herd[]) => {
    const start = getFirstDateTimeIn(grazingData);
    const end = getLastDateTimeOut(grazingData) || DateTime.now();
    if (!start) {
        console.error('No start date found! Exit SDA calculation');
        return [];
    }

    let sdaKey: { [year: string]: stockDensityEvent[] } = {};
    const endDateTime = DateTime.now() > end ? DateTime.now() : end;

    grazingData.forEach((grazeEvent) => {
        const dateIn = DateTime.fromISO(grazeEvent.dateIn);
        const dateOut = DateTime.fromISO(grazeEvent.dateOut);

        // 	For each year event is a part of
        for (let year = dateIn.year; year <= dateOut.year; year++) {
            const yearString = year.toString();
            const startDay = year === dateIn.year ? dateIn : DateTime.local(year, 1, 1);
            const endDay = year === dateOut.year ? dateOut : DateTime.local(year, 12, 31);

            const days = endDay.day - startDay.day + 1;
            // console.log('days', days);

            // 	get acres (splits or base pasture)
            let acres = grazeEvent.cellSize || grazeEvent.splitSize || -1;
            if (acres === -1 || acres === null || isNaN(acres)) {
                const pasture = pastures.find((pi) => {
                    // console.log('pi', pi);
                    // console.log('grazeEvent', grazeEvent);
                    return getCleanPastureName(pi) === getCleanPastureName(grazeEvent);
                });
                if (!pasture) {
                    console.error('Pasture not found!');
                    return [];
                }
                // console.log('pasture', pasture);
                acres = pasture.acres;
            }
            // console.log('acres', acres);
            // 	Get rancher weight
            // console.log('looking for herd: ', grazeEvent.herdName);
            // console.log('looking in ', herds);
            const herd = herds.find((h) => keyCompare(h.name, grazeEvent.herdName));
            if (!herd || !herd.animalConversion) {
                console.error('Herd not found!');
                return [];
            }
            // console.log('herd', herd);

            // 	calc SDAs
            const stockDensity = grazeEvent.herdSize * herd.animalConversion;

            const stockDensityAcre = stockDensity / acres;

            const currentGrazeDays = range(days, startDay.ordinal);
            // console.log('currentGrazeDays', currentGrazeDays);
            // const allYearGrazeDays = sdaKey[yearString].grazeDates.concat(currentGrazeDays).filter(onlyUnique);

            const grazeEventObject = {
                stockDensityAcre,
                grazeDates: currentGrazeDays,
                grazeLength: currentGrazeDays.length,
            };

            if (sdaKey[yearString] === undefined) {
                sdaKey[yearString] = [];
            }
            sdaKey[yearString].push(grazeEventObject);
            // console.log('allMonthGrazeDays', allMonthGrazeDays);
            // console.log('SDA', sda);
            // console.log('sdaKey[SDAMonth]', sdaKey[SDAMonth]);
        }
    });

    const weightedAverages: { [year: string]: number } = {};

    // Pull Unique Graze Days
    Object.keys(sdaKey).forEach((key) => {
        // For each year
        const yearTotalDays = sdaKey[key].reduce((sum, curr) => sum + curr.grazeLength, 0);
        const yearAverage = sdaKey[key].reduce((avg, curr) => {
            return avg + (curr.stockDensityAcre * curr.grazeLength) / yearTotalDays;
        }, 0);
        weightedAverages[key] = yearAverage;
    });

    // console.log('sdas ', Object.values(sdaKey));
    return weightedAverages;
};

export const calculateSDAMonthly = (grazingData: GrazingData[], pastures: Pasture[], herds: Herd[]): SDAMonthly[] => {
    const start = getFirstDateTimeIn(grazingData);
    const end = getLastDateTimeOut(grazingData) || DateTime.now();
    // console.log('start: ', start.toISO());
    // console.log('end: ', end.toISO());

    if (!start) {
        console.error('No start date found! Exit SDA calculation');
        return [];
    }

    let sdaKey: SDAKey = {};

    const endDateTime = DateTime.now() > end ? DateTime.now() : end;

    // console.log('##############################################');
    // console.log('start', start.toISO());
    // console.log('endDateTime', endDateTime.toISO());
    // console.log('##############################################');

    // 	Make 0s array
    for (let year = start.year; year <= endDateTime.year; year++) {
        for (let month = 1; month < 13; month++) {
            const SDAMonth = yearMonthFromNumbers(year, month);

            sdaKey[SDAMonth] = {
                SDAMonth: SDAMonth,
                grazeDays: 0,
                stockdaysPerAcre: 0,
                grazeDates: [],
                month: month,
                year: year,
            };
        }
    }

    // console.log('sdaKey', sdaKey);

    grazingData.forEach((grazeEvent) => {
        // console.log('grazeEvent', grazeEvent);
        const dateIn = DateTime.fromISO(grazeEvent.dateIn);
        const dateOut = DateTime.fromISO(grazeEvent.dateOut);

        // 	For each month event is a part of
        for (let year = dateIn.year; year <= dateOut.year; year++) {
            const startMonth = year === dateIn.year ? dateIn.month : 1;
            const endMonth = year === dateOut.year ? dateOut.month : 12;

            for (let month = startMonth; month <= endMonth; month++) {
                const SDAMonth = yearMonthFromNumbers(year, month);
                // console.log('year-month', SDAMonth);
                // 	calc days in pasture
                const isStartMonth = dateIn.year === year && dateIn.month === month;
                const isEndMonth = dateOut.year === year && dateOut.month === month;
                // start is last day of month unless is start month
                let startDay = DateTime.local(year, month, 1);
                if (isStartMonth) {
                    startDay = dateIn;
                }
                // console.log("startDay", startDay.toISO())
                // end is last day of month unless is end month
                let endDay = DateTime.local(year, month, 1).plus({ months: 1 }).minus({ days: 1 });
                if (isEndMonth) {
                    endDay = dateOut;
                }
                // console.log("endDay", endDay.toISO())
                // assume both move days are half, round to +1
                const days = endDay.day - startDay.day + 1;
                // console.log('days', days);

                // 	get acres (splits or base pasture)
                let acres = grazeEvent.cellSize || grazeEvent.splitSize || -1;
                if (acres === -1 || acres === null || isNaN(acres)) {
                    const pasture = pastures.find((pi) => {
                        // console.log('pi', pi);
                        // console.log('grazeEvent', grazeEvent);
                        return getCleanPastureName(pi) === getCleanPastureName(grazeEvent);
                    });
                    if (!pasture) {
                        console.error('Pasture not found!');
                        return [];
                    }
                    // console.log('pasture', pasture);
                    acres = pasture.acres;
                }
                // console.log('acres', acres);
                // 	Get rancher weight
                // console.log('looking for herd: ', grazeEvent.herdName);
                // console.log('looking in ', herds);
                const herd = herds.find((h) => keyCompare(h.name, grazeEvent.herdName));
                if (!herd || !herd.animalConversion) {
                    console.error('Herd not found!');
                    return [];
                }
                // console.log('herd', herd);

                // 	calc SDAs
                const stockDensity = grazeEvent.herdSize * herd.animalConversion;

                const sda = (stockDensity * days) / acres;
                // console.log('stockDensity', stockDensity);
                // console.log('days', days);
                // console.log('acres', acres);

                const currentGrazeDays = range(days, startDay.day);
                // console.log('currentGrazeDays', currentGrazeDays);
                const allMonthGrazeDays = sdaKey[SDAMonth].grazeDates.concat(currentGrazeDays).filter(onlyUnique);

                // console.log('allMonthGrazeDays', allMonthGrazeDays);
                // console.log('SDA', sda);
                // console.log('sdaKey[SDAMonth]', sdaKey[SDAMonth]);
                sdaKey[SDAMonth].grazeDates = allMonthGrazeDays;
                sdaKey[SDAMonth].stockdaysPerAcre += sda;
            }
        }
    });

    // Pull Unique Graze Days
    Object.keys(sdaKey).forEach((key) => {
        sdaKey[key].grazeDates = sdaKey[key].grazeDates.filter(onlyUnique);
        sdaKey[key].grazeDays = sdaKey[key].grazeDates.length;
        // console.log('For Month: ', sdaKey[key].SDAMonth, ' graze Dates: ', sdaKey[key].grazeDates);
        // console.log('Days: ', sdaKey[key].grazeDays);
    });

    // console.log('sdas ', Object.values(sdaKey));
    return Object.values(sdaKey);
};

// const getAcresForGrazeMove = (grazeEvent: GrazingData, pastures: Pasture[]): number => {
//     let acres = grazeEvent.cellSize || grazeEvent.splitSize || -1;
//     if (acres === -1 || acres === null || isNaN(acres)) {
//         const pasture = pastures.find((pi) => {
//             // console.log('pi', pi);
//             // console.log('grazeEvent', grazeEvent);
//             return getCleanPastureName(pi) === getCleanPastureName(grazeEvent);
//         });
//         if (!pasture) {
//             console.error('Pasture not found!');
//             throw new Error('Pasture not found trying to calculate acres!');
//         }
//         // console.log('pasture', pasture);
//         acres = pasture.acres;
//     }
// };

export const getTotalGrazeDaysByPasture = (ranchGrazeData: GrazingData[], filterYear: DateTime | null = null) => {
    // How many days were animals in a given pasture
    let pastureGrazeDays: { [ranchName: string]: Interval[] } = {};
    // console.log('filterYear', filterYear?.toISO());

    ranchGrazeData.forEach((gd) => {
        const pastureName = getCleanPastureName(gd);
        const dateIn = getDateTime(gd.dateIn)!;
        const dateOut = getDateTime(gd.dateOut) || DateTime.now();
        // const grazeDays = dateOut.diff(dateIn, ['days']).days;
        // const days = dateOut.ordinal - dateIn.ordinal + 1;
        const currentInterval = Interval.fromDateTimes(dateIn, dateOut.set({ hour: 23, minute: 59, second: 59 }));

        // console.log('pastureName', pastureName);
        // console.log('dateIn', dateIn.toISO());
        // console.log('dateOut', dateOut.toISO());
        // console.log('grazeDays', grazeDays);
        if (pastureName) {
            if (pastureName in pastureGrazeDays) {
                const lastIntervalIdx = pastureGrazeDays[pastureName].length - 1;
                const lastInterval = pastureGrazeDays[pastureName][lastIntervalIdx];

                if (lastInterval.overlaps(currentInterval)) {
                    // console.log('Found Overlap!');
                    pastureGrazeDays[pastureName][lastIntervalIdx] = lastInterval.union(currentInterval);
                } else {
                    pastureGrazeDays[pastureName].push(currentInterval);
                }
            } else {
                pastureGrazeDays[pastureName] = [currentInterval];
            }
        }
    });

    // Reduce Intervals to total days
    let pastureGrazeDaysTotal: { [ranchName: string]: number } = {};

    Object.keys(pastureGrazeDays).forEach((key) => {
        pastureGrazeDaysTotal[key] = pastureGrazeDays[key].reduce((acc, curr) => {
            let accumulator = acc;
            if (!filterYear || filterYear === MAX_DATE) {
                accumulator += curr.length('days');
            } else {
                const filterYearInterval = Interval.fromDateTimes(
                    filterYear.set({ day: 1, month: 1 }),
                    filterYear.set({ day: 31, month: 12 })
                );
                // console.log('curr.start', curr.start?.toISO());
                // console.log('curr.end', curr.end?.toISO());
                // console.log('filterYearInterval.start', filterYearInterval.start?.toISO());
                // console.log('filterYearInterval.end', filterYearInterval.end?.toISO());
                if (curr.overlaps(filterYearInterval)) {
                    const overlap = curr.intersection(filterYearInterval);
                    // console.log('overlap', overlap);
                    accumulator += overlap!.length('days');
                }
            }
            return round(accumulator, 1);
        }, 0);
    });

    return pastureGrazeDaysTotal;
};

export const getRestDaysSinceLastGraze = (ranchGrazeData: GrazingData[], filterYear: DateTime | null = null) => {
    const daysSinceLastGraze: { [pastureName: string]: number } = {};

    ranchGrazeData.forEach((gd) => {
        const pastureName = getCleanPastureName(gd);
        if (!pastureName) {
            console.error('No pasture name found!');
        } else {
            const lastGraze = getDateTime(gd.dateOut) || DateTime.now();
            const daysSince = round(DateTime.now().diff(lastGraze, 'days').days, 1);
            if (pastureName in daysSinceLastGraze) {
                if (daysSinceLastGraze[pastureName] > daysSince) {
                    daysSinceLastGraze[pastureName] = daysSince;
                }
            } else {
                daysSinceLastGraze[pastureName] = daysSince;
            }
        }
    });

    return daysSinceLastGraze;
};

export const getRestDaysByPasture = (
    ranchGrazeData: GrazingData[],
    filterYear: DateTime
): { [ranchName: string]: number } | null => {
    let grazePastures: string[] | null = null;
    let restDays: number[] | null = null;

    try {
        const yearFilterGrazeData = ranchGrazeData.filter((gd) => {
            const dateOverlap = filterYear.equals(MAX_DATE)
                ? true
                : datesHaveOverlap(
                      getDateTime(gd.dateIn)!,
                      getDateTime(gd.dateOut) || DateTime.now(),
                      filterYear.set({ day: 1, month: 1 }),
                      filterYear.set({ day: 31, month: 12 })
                  );
            return dateOverlap;
        });
        // let utilizationData: number[] = [];
        // let pastures: string[] = [];
        // get total days grazed
        const grazeDays = getTotalGrazeDaysByPasture(yearFilterGrazeData, filterYear);
        // console.log('grazeDays', grazeDays);

        // Take Year Space and subtract total graze days to get rest days
        // const restDays: { [ranchName: string]: number } = {};
        let restDaysMap: { [ranchName: string]: number } = {};

        Object.keys(grazeDays).forEach((pasture) => {
            const days = grazeDays[pasture];
            let startRange: DateTime;
            let endRange: DateTime = DateTime.now();

            if (filterYear.equals(MAX_DATE)) {
                const firstEvent = getFirstDateTimeIn(ranchGrazeData);
                if (!firstEvent) throw new Error('No first event found');
                startRange = firstEvent.set({ day: 1, month: 1 });
            } else {
                startRange = filterYear.set({ day: 1, month: 1 });

                const yearEnd = filterYear.set({ day: 31, month: 12 });
                endRange = yearEnd > endRange ? endRange : yearEnd;
            }

            const daysInRange = endRange.diff(startRange, 'days').days;

            restDaysMap[pasture] = round(daysInRange - days, 2);
        });

        return restDaysMap;

        //  restDays = Object.values(restDaysMap);
        //  grazePastures = Object.keys(restDaysMap);

        // grazeDays.forEach()
        // console.log('days', days);
        // console.log('pasture', pasture);
        // if(filterYear.equals(MAX_DATE)) {

        // } else {
        //   restDays[pasture] = 365 - days;
        // }
        // });

        // const grazePastures = Object.keys(grazeDays);
        // const utilizationData = Object.values(grazeDays);
        // console.log('Object.keys(grazeDays)', Object.keys(grazeDays));
    } catch (e) {
        console.error('Error in getting Rest Days Over Time');
        console.error(e);
    }
    return null;
};

export const buildGrazingData = (
    grazeEventFormData: GrazingDataFormInput,
    customCellDetails: CellDetail[] | null,
    pastureAcres: number,
    ranch: string,
    managementUnit: string,
    dataset: 'grazing-data' | 'grazing-plan'
) => {
    console.log('Dataset:', dataset);
    let grazeEvents: (GrazingData | GrazingPlan)[] = [];
    let removeGrazeEventSKs: string[] = [];
    let cellDetails = customCellDetails;
    // console.log('ranch', ranch);

    const cells = grazeEventFormData.cells;
    const dateIn = getDateTime(grazeEventFormData.dateIn)?.set({ hour: 12 });
    const dateOut = getDateTime(grazeEventFormData.dateOut)?.set({ hour: 12 });

    // Need Dates
    if (!dateIn || !dateOut) {
        console.error('Date In or Date Out is null');
        return null;
    }

    let inSK: string | null = null;

    if (grazeEventFormData.sk) {
        if (cellDetails || grazeEventFormData.herdSizes.length > 1) {
            removeGrazeEventSKs.push(grazeEventFormData.sk);
        } else {
            inSK = grazeEventFormData.sk;
        }
    }

    grazeEventFormData.herdSizes.forEach((herdSize) => {
        if (!cellDetails) {
            // Make one graze event
            let newGrazeEvent: GrazingData | GrazingPlan = {
                pastureName: grazeEventFormData.pastureName,
                dateIn: dateIn.toISO(),
                dateOut: dateOut.toISO(),
                cellSize: pastureAcres,
                cells: Number(cells),
                herdName: herdSize.name,
                herdSize: Number(herdSize.size),
                notes: grazeEventFormData.notes,
                ranch,
                managementUnit,
                objectClass: 'DataObservation',
                objectType: dataset,
            };
            if (inSK) {
                newGrazeEvent.sk = inSK;
            }

            grazeEvents.push(newGrazeEvent);
        } else {
            // each are unique graze events
            cellDetails!.forEach((cellDetail) => {
                console.log('cellDetail', cellDetail);
                let newGrazeEvent: GrazingData | GrazingPlan = {
                    pastureName: grazeEventFormData.pastureName,
                    dateIn: getDateTime(cellDetail.dateIn)?.toISO(),
                    dateOut: getDateTime(cellDetail.dateOut)?.toISO(),
                    cellSize: Number(cellDetail.acres),
                    herdName: herdSize.name,
                    herdSize: Number(herdSize.size),
                    notes: grazeEventFormData.notes,
                    cells: 1,
                    ranch,
                    managementUnit,
                    objectClass: 'DataObservation',
                    objectType: dataset,
                };
                if (inSK) {
                    newGrazeEvent.sk = inSK;
                }
                grazeEvents.push(newGrazeEvent);
            });
        }
    });

    return { grazeEvents, removeGrazeEventSKs };
};

export const calcGrazeCells = (
    cells: number,
    inStartDate: DateTime | Date,
    inEndDate: DateTime | Date,
    pastureAcres: number
) => {
    const startDate = getDateTime(inStartDate)?.set({ hour: 12 });
    const endDate = getDateTime(inEndDate)?.set({ hour: 12 });

    if (cells && pastureAcres && startDate && endDate) {
        const cellAcres = pastureAcres / cells;
        const totalDuration = endDate.diff(startDate).milliseconds;
        const duration = totalDuration / cells;

        let cellRows: CellDetail[] = [];
        // console.log('duration', duration);
        for (let i = 0; i < cells; i++) {
            const splitStart = startDate.plus({ milliseconds: duration * i });
            let splitEnd = splitStart.plus({ milliseconds: duration });
            if (i === cells - 1) {
                splitEnd = endDate;
            }
            const acres = round(pastureAcres / cells);
            const dateIn = splitStart;
            const dateOut = splitEnd;

            cellRows.push({ dateIn, dateOut, acres });
            // console.log('cellRow', { dateIn: dateIn.toISO(), dateOut: dateOut.toISO(), acres });
        }

        return cellRows;
    }
    return null;
};

export const getAverageGrazeLengthPerYear = (grazingData: GrazingData[]) => {
    const grazeLengthsPerYear: { [year: string]: number[] } = {};

    grazingData.forEach((gd) => {
        const dateIn = getDateTime(gd.dateIn);
        const dateOut = getDateTime(gd.dateOut) || DateTime.now();

        if (!dateIn) {
            const errMsg = 'Error calculating average graze length, No dateIn found!';
            console.error(errMsg);
            throw Error(errMsg);
        }

        const dateInYear = dateIn.year;
        const dateOutYear = dateOut.year;

        for (let year = dateInYear; year <= dateOutYear; year++) {
            const start = year === dateInYear ? dateIn : DateTime.local(year, 1, 1);
            const end = year === dateOutYear ? dateOut : DateTime.local(year, 12, 31);
            const days = end.diff(start, 'days').days;

            let dayArray = [days];

            // If cells, then account for length in each cell
            if (gd.cells && gd.cells > 1) {
                const cellDuration = days / gd.cells;
                dayArray = Array(gd.cells).fill(cellDuration);
            }

            if (year in grazeLengthsPerYear) {
                grazeLengthsPerYear[year] = grazeLengthsPerYear[year].concat(dayArray);
            } else {
                grazeLengthsPerYear[year] = dayArray;
            }
        }
    });
    const averageGrazePerYear: { [year: string]: number } = {};

    Object.keys(grazeLengthsPerYear).forEach((yearGrazes) => {
        const yearGrazeLengths = grazeLengthsPerYear[yearGrazes];

        const countOfGrazes = yearGrazeLengths.length;
        const totalGrazeDays = yearGrazeLengths.reduce((acc, curr) => acc + curr, 0);
        const averageGraze = totalGrazeDays / countOfGrazes;

        averageGrazePerYear[yearGrazes] = averageGraze;
    });

    return averageGrazePerYear;
};

export const getRestedAcresPerYear = (grazingData: GrazingData[], pastures: Pasture[]) => {};
