import { DateTime } from 'luxon';

const displayFormat = 'MM/dd/yy';

const formFormat = 'yyyy-MM-dd';

const formDateTimeFormat = 'yyyy-MM-dd HH:mm';

const invalidTimeStrings = ['nan', 'invalid date', 'undefined', 'null', 'na'];

type TimestampTypes = Date | number | string | DateTime | undefined;

export const MAX_DATE = DateTime.fromISO('9999-12-31T00:00:00.000Z');

export const getHTMLTodayDate = (): string => {
    const today = DateTime.now();
    return today.toFormat(displayFormat);
    // const year = today.getFullYear();
    // const month = String(today.getMonth() + 1).padStart(2, '0'); // months are 0-based in JS
    // const day = String(today.getDate()).padStart(2, '0');
    // return `${year}-${month}-${day}`;
};

export function excelDateToLuxon(serial: number): DateTime {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return DateTime.fromJSDate(
        new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate() + 1, hours, minutes, seconds)
    );
}

export function tabularDateToLuxon(date: string | number): DateTime | null {
    let DTDate: DateTime | null;
    switch (typeof date) {
        case 'string':
            DTDate = DateTime.fromJSDate(new Date(date));
            break;
        case 'number':
            DTDate = excelDateToLuxon(date);
            break;
        default:
            DTDate = getDateTime(date);
    }
    return DTDate;
}

export function getDateTime(timestamp: TimestampTypes): DateTime | null {
    let dateTime = null;
    const invalidValue = null;

    // console.log('timestamp', timestamp);
    if (!timestamp) {
        return null;
    } else if (timestamp instanceof DateTime) {
        if (timestamp.isValid) {
            dateTime = timestamp;
        } else {
            return invalidValue;
        }
    } else if (typeof timestamp === 'number') {
        if (isNaN(timestamp)) {
            return invalidValue;
        } else {
            dateTime = DateTime.fromSeconds(timestamp);
        }
    } else if (typeof timestamp === 'string') {
        if (invalidTimeStrings.includes(timestamp.toLowerCase())) {
            return invalidValue;
        } else if (timestamp.includes('T')) {
            dateTime = DateTime.fromISO(timestamp);
        } else {
            dateTime = DateTime.fromSQL(timestamp);
        }
    } else if (Object.prototype.toString.call(timestamp) === '[object Date]') {
        return DateTime.fromJSDate(timestamp);
    }

    return dateTime;
}

export function formatDate(timestamp: TimestampTypes): string {
    // console.log('dateTime', dateTime.toISO);
    const dt = getDateTime(timestamp);
    if (!dt) return 'NA';

    return dt.toFormat(displayFormat);
}

export function hyperDatepickerFormat(timestamp: TimestampTypes): Date | null {
    const dt = getDateTime(timestamp);
    if (!dt) return null;

    return dt.toJSDate();
}

export function formFormatDate(timestamp: TimestampTypes): string | null {
    // console.log('dateTime', dateTime.toISO);
    const dt = getDateTime(timestamp);
    if (!dt) return null;

    return dt.toFormat(formFormat);
}

export function formFormatDateTime(timestamp: TimestampTypes): string | null {
    // console.log('dateTime', dateTime.toISO);
    const dt = getDateTime(timestamp);
    if (!dt) return null;

    return dt.toFormat(formDateTimeFormat);
}

export function dateInTimeRange(date: DateTime, start: DateTime, end: DateTime): boolean {
    return date >= start && date <= end;
}

export function datesHaveOverlap(start1: DateTime, end1: DateTime, start2: DateTime, end2: DateTime): boolean {
    return start1 <= end2 && end1 >= start2;
}

export function wait(seconds: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
}

export function dateTimeSort(a: any, b: any) {
    const timeA = getDateTime(a);
    const timeB = getDateTime(b);
    if (!timeA && !timeB) return 0;
    if (!timeA) return -1;
    if (!timeB) return 1;
    return timeA.toMillis() - timeB.toMillis();
}
