import axiosInstance from './apiCore';
import appSyncClient from './appSyncClient';

export const getAllUsers = async (): Promise<User[]> => {
    const res = await axiosInstance.post('getAllUsers');

    // console.log('Users: ', res);
    return res.data.filter((u: User) => u.email != null);
};

export const deactivateUser = async (user: User) => {
    const res = await axiosInstance.post('deactivateUser', user);
    return res.data;
};

export const updateUser = async (user: User) => {
    const res = await axiosInstance.post('updateUser', user);
    return res.data;
};

export const getAuthenticatedUser = async (): Promise<User> => {
    const res = await axiosInstance.post('getUser');
    return res.data;
};

export const inviteUserToRanch = async (email: string, ranchName: string, newUserRole: RanchUserRoll) => {
    console.log('Ivite User');
    const res = await axiosInstance.post('inviteUserToRanch', { email, ranchName, newUserRole });
    console.log(res);
    return res.data;
};

export const assignRanchOwner = async (email: string, ranchName: string) => {
    const res = await axiosInstance.post('assignRanchOwner', { email, ranchName });
    return res.data;
};
