import React, { createContext, useState, useEffect, PropsWithChildren, useContext, useMemo } from 'react';
import { AUTH_URL, LS_REFRESH_TOKEN_KEY, LS_TOKEN_KEY, LS_USER_KEY } from '../services/constants';
import * as Sentry from '@sentry/react';
import { useLocalStorage } from 'usehooks-ts';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'services/apiCore';
import { getAuthenticatedUser } from 'services/Users';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within AuthProvider');
    }
    return context;
};

export type AuthContextType = {
    user: User | null;
    login: (email: string, password: string) => User | null | any;
    logout: () => void;
    // userRoll: UserRoll;
    register: (details: any) => Promise<User>;
    confirmPin: (details: any) => Promise<void>;
    resendPin: (detials: any) => Promise<void>;
    resetPassword: (detials: any) => Promise<void>;
    resetPasswordPin: (detials: any) => Promise<void>;
    userProfileImage: string;
    isSuperAdmin: boolean;
    isAuthenticated: boolean;
    refreshAuthToken: () => Promise<void>;
    groupTrees: null | string[];
    updateUserObject: () => Promise<void>;
};

const CognitoTokenTimeout = 1.5 * 60 * 60; // 1.5 hour

const AuthProvider: React.FC = ({ children }: PropsWithChildren<any>) => {
    const [user, setUser] = useLocalStorage<User | null>(LS_USER_KEY, null);
    const [token, setToken] = useLocalStorage<Token | null>(LS_TOKEN_KEY, null);
    const [refreshToken, setRefreshToken] = useLocalStorage<Token | null>(LS_REFRESH_TOKEN_KEY, null);
    const [lastRequestLocalTime, setLastRequestLocalTime] = useState<DateTime | null>(null);

    const navigate = useNavigate();

    // Session Timeout

    const checkTimeLeft = () => {
        // console.log('Last Request Time: ', lastRequestLocalTime?.toISO());
        if (!lastRequestLocalTime) return;
        const timeout = lastRequestLocalTime.plus({ seconds: CognitoTokenTimeout });

        // console.log('timeout: ', timeout.toISO());

        if (DateTime.now() > timeout) {
            console.log('SESSION TIMEOUT');
            navigate('/sessionExpired');
            // refreshAuthToken();
        }
    };

    useEffect(() => {
        if (lastRequestLocalTime) {
            // Set up an interval to check the session timeout periodically
            const intervalId = setInterval(checkTimeLeft, 1000 * 10);

            return () => clearInterval(intervalId); // Cleanup on component unmount
        }
    }, [lastRequestLocalTime]);

    const logout = async () => {
        // console.log('logging out');
        setUser(null);
        setToken(null);
        setRefreshToken(null);
        setLastRequestLocalTime(null);
    };

    const groupTrees = user?.groupTrees || null;

    const login = async (email: string, password: string) => {
        const url = `${AUTH_URL}/signIn`;
        // console.log(url);
        try {
            const response = await axiosInstance({
                method: 'POST',
                url: url,
                data: JSON.stringify({ email, password }),
            }).then((res) => {
                // console.log('login res: ', res);
                return res.data;
            });

            // console.log(response);

            const { user, token, refreshToken, historicUserReset } = response;

            if (user && token && refreshToken) {
                setUser(user);
                setToken(token);
                setRefreshToken(refreshToken);
                setLastRequestLocalTime(DateTime.now());
                Sentry.setUser({ email: user.email });
            } else if (historicUserReset) {
                return { historicUserReset };
            } else {
                //     set error
                throw new Error('Authentication Failed');
            }

            return { user };
        } catch (e) {
            console.log('error loggin in', e);
            return null;
        }
    };

    const updateUserObject = async () => {
        const user = await getAuthenticatedUser();
        if (!user) {
            throw new Error('No user found');
        }
        setUser(user);
    };

    const refreshAuthToken = async () => {
        const url = `${AUTH_URL}/refreshSession`;
        // console.log(url);
        try {
            const refreshTokenLS = localStorage.getItem(LS_REFRESH_TOKEN_KEY);
            // console.log('refreshToken', refreshTokenLS);

            if (!user || !user.email || !refreshTokenLS) {
                throw new Error('Must have email and refresh token to get new token');
            }

            const refreshToken = JSON.parse(refreshTokenLS);

            const response = await axiosInstance({
                method: 'POST',
                url: url,
                data: JSON.stringify({ username: user.email, refreshToken: refreshToken.refreshToken }),
            }).then((res) => {
                // console.log('Refresh token res: ', res);
                // console.log('token', token);
                // console.log('token', window.localStorage.getItem(LS_TOKEN_KEY));
                const lsToken = { tokenType: 'idToken', idToken: res.data.idToken };
                setLastRequestLocalTime(DateTime.now());
                setToken(lsToken);
                // localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(lsToken));
                return res.data;
            });

            // console.log(response);
        } catch (e) {
            console.error('error getting refresh Auth token', e);
        }
    };

    // const updateState = () => {
    //     // console.log('updating state');
    //     let u = null;
    //     let t = null;
    //     if (!user) {
    //         u = localStorage.getItem(LS_USER_KEY);
    //         // console.log('user ls: ', u);
    //         if (u) {
    //             setUser(JSON.parse(u));
    //         }
    //     } else {
    //         u = user;
    //     }

    //     if (!token) {
    //         t = localStorage.getItem(LS_TOKEN_KEY);
    //         // console.log('token ls', t);
    //         if (t) {
    //             setToken(JSON.parse(t));
    //         }
    //     } else {
    //         t = token;
    //     }
    //     // console.log(`returning user: ${u} and token: ${t}`);
    //     return { u: u, t: t };
    // };

    // const isAuthenticated = (): boolean => {
    //     const { u, t } = updateState();
    //     console.log('is authenticated');
    //     console.log('user', u);
    //     console.log('token', t);
    //     const isAuth = u !== null && t !== null;
    //     // console.log('isAuth: ', isAuth);
    //     return isAuth;
    // };

    const isAuthenticated = useMemo(() => {
        // console.log('isAuthenticated');
        // console.log('user', user);
        // console.log('token', token);
        const isAuth = user !== null && token !== null;
        // console.log('isAuth', isAuth);
        return isAuth;
    }, [user, token]);

    // const userRoll = isAuthenticated && user?.role ? user.role : 'UNAUTHENTICATED';

    const isSuperAdmin = user?.isSuperAdmin || false;

    const register = async (details: any): Promise<User> => {
        // try {
        const res = await axiosInstance.post(`${AUTH_URL}/signUp`, JSON.stringify(details));
        // .then((res) => {
        //     console.log('signUp Res: ', res);
        //     console.log('signUp Res: ', res);
        //     return res.data;
        // })
        // .catch((err) => {
        //     console.log('errors registering user: ', err);
        //     throw err;
        // });

        console.log('AUTH CONTEXT REGISTER RES: ', res);

        return res.data.user;

        // if (user && token) {
        //     setUser(user);
        //     setToken(token);
        //     localStorage.setItem(LS_USER_KEY, JSON.stringify(user));
        //     localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(token));
        //
        //     return user;
        // } else {
        //     throw new Error('user or token missing');
        // }
        // } catch (err) {

        // }
    };

    const confirmPin = async (details: any): Promise<void> => {
        try {
            const res = await axiosInstance({
                method: 'POST',
                url: `${AUTH_URL}/verifyPin`,
                data: JSON.stringify(details),
            });
            console.log('confirm Pin', res);
        } catch (err) {
            console.log('error confirming pin ', err);
            throw err;
        }
    };

    const resendPin = async (email: string) => {
        try {
            console.log('resendPin');
            await axiosInstance({
                method: 'POST',
                url: `${AUTH_URL}/resendPin`,
                data: JSON.stringify({ email }),
            });
        } catch (err) {
            console.log('error resending pin ', err);
            throw err;
        }
    };

    const resetPasswordPin = async (details: any) => {
        try {
            const res = await axiosInstance({
                method: 'POST',
                url: `${AUTH_URL}/resetPasswordPin`,
                data: JSON.stringify(details),
            });
            console.log('reset password Pin', res);
        } catch (err) {
            console.log('error resetting password pin ', err);
            throw err;
        }
    };

    const resetPassword = async (details: any) => {
        try {
            const res = await axiosInstance({
                method: 'POST',
                url: `${AUTH_URL}/resetPassword`,
                data: JSON.stringify(details),
            });
            console.log('reset Password', res);
            // return res;
        } catch (err) {
            console.log('error reseting password ', err);
            throw err;
        }
    };

    const userProfileImage = useMemo(() => {
        if (user?.profileImage) {
            return user.profileImage;
        } else {
            return 'cow-1.svg';
        }
    }, [user]);

    return (
        <AuthContext.Provider
            value={{
                user,
                logout,
                login,
                // userRoll,
                register,
                confirmPin,
                resendPin,
                resetPassword,
                resetPasswordPin,
                userProfileImage,
                isSuperAdmin,
                isAuthenticated,
                refreshAuthToken,
                groupTrees,
                updateUserObject,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export { useAuth, AuthProvider };
