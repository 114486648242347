import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import React from 'react';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

process.env.NODE_ENV === 'production' &&
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        dsn: 'https://0f46b71fd5b1adb8072381122bbf6a30@o4506367205965824.ingest.sentry.io/4506385606967296',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                // tracePropagationTargets: [process.env.REACT_APP_REST_URL || '', process.env.REACT_APP_AUTH_URL || ''],
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],

        // Performance Monitoring
        tracesSampleRate: 0.5, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

console.log('NODE_ENV', process.env.NODE_ENV);

ReactDOM.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
